<template>
  <div>
    <main class="main">
        <header id="site-header" role="header">
            <div class="app-install clearfix visible-xs visible-sm" style="display: none !important;">
                <a href="#" class="btnClose">x</a>
                <div class="app-install-content">
                    <div class="visual">
                        <img id="p_lt_ctl00_imgAppInstallBannerIcon_ucEditableImage_imgImage" src="https://www.magnum4d.my/Magnum4d/media/images/icons/icon-app.png?ext=.png" alt="" />


                    </div>
                    <div class="description">
                        <p>Download Magnum App</p>
                    </div>
                </div>
                <div class="app-install-buttons">
                    <a class="cta ios" href="#" style="display: none;">Install</a>
                    <a class="cta android" href="#" style="display: none;">Install</a>
                </div>
            </div>
            <div id="emergency-notice-desktop" class="emergency-notice" style="display:none !important;">
                <div class="float-right">
                    <a class="btnClose text-right" href="#">x</a>
                </div>
                <div id="emergency-notice-text" class="emergency-notice-message">
                    
                </div>
            </div>
            <div class="navbar-wrapper hidden-xs hidden-sm">
                <div class="container">
                    <div class="row-table">
                        <div class="col-cell">
                            <a href="#" class="header-logo rw-url" onclick="ga('send','event','Top Navigations','Click','Home - Magnum Icon')">
                                <img src="https://www.magnum4d.my/Magnum4d/media/images/Logo-big.png" alt="Magnum">
                            </a>
                        </div>
                        <div class="col-cell is-middle">
                            <div class="navbar-desktop row-0 middle-xs end-xs">
                                <ul class="desktop-menu-items">
                                    <li><a href="#" id="mega-menu-btn" data-toggle="modal" data-target="#header-mega-menu" onclick="ga('send','event','Top Navigations','Click','Home - Our Games')">Our Games



</a></li>
                                    <!-- 20191009 Kenneth add new tab menu for 50 & Beyond -->
                                    <li><a class="main-nav-btn rw-url" href="#" onclick="ga('send','event','Top Navigations','Click','Home - 50 and Beyond')">50 &amp; Beyond</a>



</li>
                                    <li><a href="#" class="rw-url" onclick="ga('send','event','Top Navigations','Click','Home - Number Inspirations')">Number Inspirations



</a></li>
                                    <li><a href="#" class="rw-url" onclick="ga('send','event','Top Navigations','Click','Home - Draw Results')">Draw Results



</a></li>
                                    <li><a href="#" class="rw-url" onclick="ga('send','event','Top Navigations','Click','Home - Highlights')">News



</a></li>
                                    <li><a href="#" id="magnum-care-btn" data-toggle="modal" data-target="#magnumcares-mega-menu" onclick="ga('send','event','Top Navigations','Click','Home - MagnumCares')">MagnumCares



</a></li>
                                    <li style="display: none;"><a href="#" id="magnum-menu-btn" data-toggle="modal" data-target="#magnumcares-mega-menu" onclick="ga('send','event','Top Navigations','Click','Home - MagnumCares')">MagnumCares



</a></li>
                                    <!-- 20211216 edited by Eddy where Help is replaced by Zendesk -->
                                    <!--<li><a href="/help" class="rw-url" onclick="ga('send','event','Top Navigations','Click','Home - Help')">Help



</a></li>-->
                                    <li><a href="#" class="rw-url" onclick="ga('send','event','Top Navigations','Click','Home - Help'); clickHelp(); return false;">Help



</a></li>
                                </ul>
                                <ul class="desktop-dock-items">
                                    <li class="location">
                                        <a href="#" class="rw-url"><i class="fa fa-map-marker" aria-hidden="true"></i></a>
                                    </li>
                                    <li class="language">
                                        <select class="cmbLanguageSelect language-select">
                                            <option value="en-us">ENG</option>
                                            <option value="zh-cn">中文</option>
                                        </select>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div id="header-mega-menu" class="header-mega-menu modal modal-show-below">
                <div class="panel-group game-panel-group modal-dialog" id="game-panel-group" role="tablist" aria-multiselectable="true">
                    <!-- Nav 4d -->
                    <div class="panel">
                        <div class="panel-heading game-panel-heading" role="tab">
                            <a class="game-panel-hidden-btn hidden" role="button" data-toggle="collapse" data-parent="#game-panel-group" href="#game-panel-4d" aria-controls="game-panel-4d"> </a>
                            <div class="game-panel-btn">
                                <span class="row row-0">
                                    <span class="col-xs-12 col-p-0 game-panel-full game-panel-4d">
                                        <a href="#" class="rw-url"  role="button">
                                            <span class="game-panel-image">
                                                <img src="https://www.magnum4d.my/Magnum4d/media/images/our-games/logo-classic.png" alt="4D">
                                            </span>
                                            <span class="game-panel-bg"></span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div id="game-panel-4d" class="game-panel-content panel-collapse collapse" role="tabpanel">
                            <div class="row row-0">
                                <div class="col-xs-12 col-p-0">
                                    <div class="panel-body game-menu-content game-panel-4d">
                                        <p>
                                            This classic is the original 4D lottery game.<br />
Simple to understand, easy to play and an all-time favourite among our gamers.




                                        </p>
                                        <div class="flex-row">
                                            <div class="header-number-scroller lucky-numbers inner-vertical-mid number-white-border " data-type="default"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!-- Nav jackpot -->
                    <div class="panel">
                        <div class="panel-heading game-panel-heading" role="tab">
                            <a class="game-panel-hidden-btn hidden" role="button" data-toggle="collapse" data-parent="#game-panel-group" href="#" aria-controls="game-panel-jackpot"> </a>
                            <div class="game-panel-btn">
                                <span class="row row-0">
                                    <span class="col-xs-12 col-p-0 game-panel-full game-panel-jackpot">
                                        <a href="#" class="rw-url" role="button">
                                            <span class="game-panel-image">
                                                <img src="https://www.magnum4d.my/Magnum4d/media/images/our-games/logo-jackpot.png" alt="4D Jackpot">
                                            </span>
                                            <span class="game-panel-bg"></span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div id="game-panel-jackpot" class="game-panel-content panel-collapse collapse" role="tabpanel">
                            <div class="row row-0">
                                <div class="col-xs-12 col-p-0">
                                    <div class="panel-body game-menu-content game-panel-jackpot">
                                        <p>
                                            When you have double the 4D luck, you&#39;ll win much, much more!<br />
Hit 2 winning 4D numbers and you&#39;ll walk away with a multi-million jackpot with a bet that&#39;s as low as RM2.




                                        </p>
                                        <div class="flex-row">
                                            <div class="lucky-numbers inner-vertical-mid number-white-border ">
                                                <div class="header-number-scroller" data-type="jackpot-1"></div>
                                                <span class="combine">
                                                        <i class="fa fa-plus"></i>
                                                    </span>
                                                <div class="header-number-scroller" data-type="jackpot-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!-- Nav Magnum Life -->
                    <div class="panel">
                        <div class="panel-heading game-panel-heading" role="tab">
                            <a class="game-panel-hidden-btn hidden" role="button" data-toggle="collapse" data-parent="#game-panel-group" href="#game-panel-magnum-life" aria-controls="game-panel-magnum-life"> </a>
                            <div class="game-panel-btn">
                                <span class="row row-0">
                                    <span class="col-xs-12 col-p-0 game-panel-full game-panel-magnum-life">
                                        <a href="#" class="rw-url" role="button">
                                            <span class="game-panel-image">
                                                <img src="https://www.magnum4d.my/Magnum4d/media/images/our-games/logo-magnum-life.png" alt="Magnum Life">
                                            </span>
                                            <span class="game-panel-bg"></span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div id="game-panel-magnum-life" class="game-panel-content panel-collapse collapse" role="tabpanel">
                            <div class="row row-0">
                                <div class="col-xs-12 col-p-0">
                                    <div class="panel-body game-menu-content game-panel-magnum-life">
                                        <p>
                                            Our latest game lets you live a thousand times better!<br />
Match all the numbers in this easy to play yet exciting to win game to win every day.




                                        </p>
                                        <div class="flex-row">
                                            <div class="lucky-numbers inner-vertical-mid number-white-border ">
                                               <div class="header-number-scroller number-leave" data-type="magnum-life"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <!-- Nav mGold -->
                    <div class="panel float-left" style="width: 50%; border-right:0">
                        <div class="panel-heading game-panel-heading" role="tab">
                            <a class="game-panel-hidden-btn hidden" role="button" data-toggle="collapse" data-parent="#game-panel-group"
                                href="#" aria-controls="game-panel-m-gold"> </a>
                            <div class="game-panel-btn">
                                <span class="">
                                    <span class="game-panel-half game-panel-mgold">
                                        <a href="#" class="rw-url" role="button">
                                            <span class="game-panel-image">
                                                <img src="https://www.magnum4d.my/Magnum4d/media/images/our-games/logo-mgold.png" alt="4D mGold">
                                            </span>
                                            <span class="game-panel-bg"></span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div id="game-panel-m-gold" class="game-panel-content panel-collapse collapse" role="tabpanel">
                            <div class="">
                                <div class="">
                                    <div class="panel-body game-menu-content game-panel-mgold">
                                        <p>
                                            <p>It&#39;s as easy as matching your 6 numbers<br />
to win a Grand Prize!</p>

<p><span style="font-size:11px;">*mGold is a variation of 4D Jackpot Gold</span></p>





                                        </p>
                                        <div class="flex-row">
                                            <div class="lucky-numbers inner-vertical-mid number-white-border ">
                                                <div class="header-number-scroller" data-type="mgold"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Nav Jackpot-Gold -->
                    <div class="panel float-right" style="width: 50%; border-left:0">
                        <div class="panel-heading game-panel-heading" role="tab">
                            <a class="game-panel-hidden-btn hidden" role="button" data-toggle="collapse" data-parent="#game-panel-group"
                                href="#" aria-controls="game-panel-jackpot-gold"> </a>
                            <div class="game-panel-btn">
                                <span class="">
                                    <span class="game-panel-half game-panel-jackpotgold">
                                        <a href="#" class="rw-url" role="button">
                                            <span class="game-panel-image">
                                                <img src="https://www.magnum4d.my/Magnum4d/media/images/our-games/logo-jackpot-gold.png"
                                                    alt="4D Jackpot Gold">
                                            </span>
                                            <span class="game-panel-bg"></span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div id="game-panel-jackpot-gold" class="game-panel-content panel-collapse collapse" role="tabpanel">
                            <div class="">
                                <div class="">
                                    <div class="panel-body game-menu-content game-panel-jackpotgold">
                                        <p>
                                            Extra digits mean extra winnings!<br />
This 6-digit* Jackpot game has a golden component added on to potentially win you a massive golden prize!




                                        </p>
                                        <div class="flex-row">
                                            <div class="lucky-numbers inner-vertical-mid number-white-border ">
                                                <div class="header-number-scroller" data-type="jackpot-gold"></div>
                                                <span class="combine">
                                                    <i class="fa fa-plus"></i>
                                                </span>
                                                <div class="number-sm">
                                                    <div class="header-number-scroller number-round circle"
                                                        data-type="jackpot-gold-ball">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    


                    <!-- <div class="panel">
                        <div class="panel-heading game-panel-heading" role="tab">
                            <a class="game-panel-hidden-btn hidden" role="button" data-toggle="collapse" data-parent="#game-panel-group" href="#game-panel-jackpot-gold" aria-controls="game-panel-jackpot-gold"> </a>
                            <div class="game-panel-btn">
                                <span class="row row-0">
                                    <span class="col-xs-6 col-p-0 game-panel-half game-panel-jackpotgold">
                                        <a href="#" class="rw-url" role="button">
                                            <span class="game-panel-image">
                                                <img src="https://www.magnum4d.my/Magnum4d/media/images/our-games/logo-jackpot-gold.png" alt="4D Jackpot Gold">
                                            </span>
                                            <span class="game-panel-bg"></span>
                                        </a>
                                    </span>
                                    <span class="col-xs-6 col-p-0 game-panel-half game-panel-mgold">
                                        <a href="/4d-mgold" class="rw-url" role="button">
                                            <span class="game-panel-image">
                                                <img src="https://www.magnum4d.my/Magnum4d/media/images/our-games/logo-mgold.png" alt="4D mGold">
                                            </span>
                                            <span class="game-panel-bg"></span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div id="game-panel-jackpot-gold" class="game-panel-content panel-collapse collapse" role="tabpanel">
                            <div class="row row-0">
                                <div class="col-xs-6 col-p-0">
                                    <div class="panel-body game-menu-content game-panel-jackpotgold">
                                        <p>
                                            Extra digits mean extra winnings!<br />
This 6-digit* Jackpot game has a golden component added on to potentially win you a massive golden prize!




                                        </p>
                                        <div class="flex-row">
                                            <div class="lucky-numbers inner-vertical-mid number-white-border ">
                                                <div class="header-number-scroller" data-type="jackpot-gold"></div>
                                                <span class="combine">
                                                    <i class="fa fa-plus"></i>
                                                </span>
                                                <div class="number-sm">
                                                    <div class="header-number-scroller number-round circle" data-type="jackpot-gold-ball"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-p-0">
                                    <div class="panel-body game-menu-content game-panel-mgold">
                                        <p>
                                            <p>It&#39;s as easy as matching your 6 numbers<br />
to win a Grand Prize!</p>

<p><span style="font-size:11px;">*mGold is a variation of 4D Jackpot Gold</span></p>





                                        </p>
                                        <div class="flex-row">
                                            <div class="lucky-numbers inner-vertical-mid number-white-border ">
                                                <div class="header-number-scroller" data-type="mgold"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
    
                    <!-- Nav Powerball -->
                    <!-- <div class="panel">
                        <div class="panel-heading game-panel-heading" role="tab">
                            <a class="game-panel-hidden-btn hidden" role="button" data-toggle="collapse" data-parent="#game-panel-group" href="#game-panel-powerball" aria-controls="game-panel-powerball"> </a>
                            <div class="game-panel-btn">
                                <span class="row row-0">
                                    <span class="col-xs-12 col-p-0 game-panel-full game-panel-powerball">
                                        <a href="/4d-powerball" class="rw-url" role="button">
                                            <span class="game-panel-image">
                                                <img src="https://www.magnum4d.my/Magnum4d/media/images/our-games/logo-powerball.png" alt="4D Powerball">
                                            </span>
                                            <span class="game-panel-bg"></span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div id="game-panel-powerball" class="game-panel-content panel-collapse collapse" role="tabpanel">
                            <div class="row row-0">
                                <div class="col-xs-12 col-p-0">
                                    <div class="panel-body game-menu-content game-panel-powerball">
                                        <p>
                                            We enhance the thrill of winning a 4D game by adding on the selection of two POWERBALLS.<br />
Match all the numbers in this game to get millions upon millions in winnings!




                                        </p>
                                        <div class="flex-row">
                                            <div class="lucky-numbers inner-vertical-mid number-white-border ">
                                                <div class="header-number-scroller" data-type="powerball"></div>
                                                <span class="combine">
                                                    <i class="fa fa-plus"></i>
                                                </span>
                                                <div class="number-sm">
                                                        <div class="header-number-scroller number-round circle" data-type="powerball-ball-1"></div>
                                                </div>
                                                <div class="margin-left-15 number-sm">
                                                    <div class="header-number-scroller number-round circle" data-type="powerball-ball-2"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
    
            <div id="magnumcares-mega-menu" class="header-mega-menu magnumcares-mega-menu modal modal-show-below">
                <div class="panel-group game-panel-group modal-dialog" id="game-panel-group" role="tablist" aria-multiselectable="true">
    
                    <div class="panel">
                        <div class="panel-heading game-panel-heading" role="tab">
                            <a class="game-panel-hidden-btn hidden" role="button" data-toggle="collapse" data-parent="#game-panel-group" href="#" aria-controls="about-magnum-cares"> </a>
                            <div class="game-panel-btn">
                                <span class="row row-0">
                                    <span class="col-xs-12 col-p-0 game-panel-full about-magnum-cares">
                                        <a href="#" class="rw-url"  role="button">
                                            <span class="game-panel-image">
                                                About MagnumCares




                                            </span>
                                            <span class="game-panel-bg"></span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
    
                    <div class="panel">
                        <div class="panel-heading game-panel-heading" role="tab">
                            <a class="game-panel-hidden-btn hidden" role="button" data-toggle="collapse" data-parent="#game-panel-group" href="#" aria-controls="our-programmes"> </a>
                            <div class="game-panel-btn">
                                <span class="row row-0">
                                    <span class="col-xs-12 col-p-0 game-panel-full our-programmes">
                                        <a href="#" class="rw-url" role="button">
                                            <span class="game-panel-image">
                                                Our Programmes




                                            </span>
                                            <span class="game-panel-bg"></span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
    
                    <div class="panel">
                        <div class="panel-heading game-panel-heading" role="tab">
                            <a class="game-panel-hidden-btn hidden" role="button" data-toggle="collapse" data-parent="#game-panel-group" href="#our-programmes" aria-controls="our-programmes"> </a>
                            <div class="game-panel-btn">
                                <span class="row row-0">
                                    <span class="col-xs-12 col-p-0 game-panel-full our-programmes">
                                        <a href="#" class="rw-url" role="button">
                                            <span class="game-panel-image">
                                                Get In Touch




                                            </span>
                                            <span class="game-panel-bg"></span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    
                    <!--
                    <div class="panel">
                        <div class="panel-heading game-panel-heading" role="tab">
                            <a class="game-panel-hidden-btn hidden" role="button" data-toggle="collapse" data-parent="#game-panel-group" href="#get-intouch" aria-controls="get-intouch"> </a>
                            <div class="game-panel-btn">
                                <span class="row row-0">
                                    <span class="col-xs-12 col-p-0 game-panel-full get-intouch">
                                        <a href="/Magnum-Care/Home#get-intouch" class="rw-url" role="button">
                                            <span class="game-panel-image">
                                                Get In Touch
                                            </span>
                                            <span class="game-panel-bg"></span>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                -->
                    
    
                </div>
            </div>
            <div class="mobile-menu hidden-md hidden-lg clearfix">
                <div class="mobile-menu-bar" role="menubar">
                    <div class="row-0">
                        <a href="#" class="header-logo rw-url" >
                            <img src="https://www.magnum4d.my/Magnum4d/media/images/logo-magnum.png" alt="Magnum">
                        </a>
                        <div class="margin-left-auto float-right">
                            <div class="mobile-menu-language">
                                <select class="cmbLanguageSelect language-select">
                                    <option value="en-us">ENG</option>
                                    <option value="zh-cn">中文</option>
                                </select>
                            </div>
                            <a class="mobile-menu-location rw-url" href="#" >
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                            </a>
                            <a id="mobile-menu-btn" class="mobile-menu-btn" data-toggle="modal" href="#">
                                <?xml version="1.0" encoding="utf-8"?>
                                <svg xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 64 64">
                                    <path id="mobile-menu-line-1" d="M59.8,33.5l-55.6,0c-0.9,0.1-1.6-0.6-1.5-1.5l0,0c0-0.8,0.7-1.5,1.5-1.5l55.6,0c0.8,0,1.5,0.7,1.5,1.5l0,0
                C61.4,32.9,60.7,33.6,59.8,33.5z"/>
                                    <path id="mobile-menu-line-2" d="M4.2,30.5l55.6,0c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5l-55.6,0c-0.8,0-1.5-0.7-1.5-1.5l0,0
                C2.6,31.1,3.3,30.4,4.2,30.5L4.2,30.5z"/>
                                    <path id="mobile-menu-line-3" d="M59.8,33.5l-55.6,0c-0.9,0.1-1.6-0.6-1.5-1.5l0,0c0-0.8,0.7-1.5,1.5-1.5l55.6,0c0.8,0,1.5,0.7,1.5,1.5l0,0
                C61.4,32.9,60.7,33.6,59.8,33.5z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="mobile-menu" class="modal mobile-menu-wrapper modal-show-below" tabindex="-1" role="menu" aria-labelledby="mobile-menu" aria-hidden="true" data-backdrop="false">
                    <div class="modal-dialog mobile-menu-content">
                        <ul class="mobile-menu-nav">
                            <li class="main-nav">
                                <a class="main-nav-btn" data-toggle="collapse" href="#">Our Games



</a>
                                <ul id="sub-nav-game-list" role="menu" class="sub-nav-wrapper collapse">
                                    <li class="sub-nav">
                                        <a href="#" class="rw-url">
                                            <i class="fa fa-circle color-classic margin-right-5"></i> 4D Game




                                        </a>
                                    </li>
                                    <li class="sub-nav">
                                        <a href="#" class="rw-url">
                                            <i class="fa fa-circle color-jackpot margin-right-5"></i> 4D Jackpot




                                        </a>
                                    </li>
                                    <li class="sub-nav">
                                        <a href="#" class="rw-url">
                                            <i class="fa fa-circle color-powerball margin-right-5"></i> Magnum Life




                                        </a>
                                    </li>
                                    <li class="sub-nav">
                                        <a href="#" class="rw-url">
                                            <i class="fa fa-circle color-mgold margin-right-5"></i> 4D mGold (A variation of 4D Jackpot Gold)




                                        </a>
                                    </li>
                                    <li class="sub-nav">
                                        <a href="#" class="rw-url">
                                            <i class="fa fa-circle color-jackpotgold margin-right-5"></i> 4D Jackpot Gold




                                        </a>
                                    </li>
                                    <!-- <li class="sub-nav">
                                        <a href="/4d-powerball" class="rw-url">
                                            <i class="fa fa-circle color-powerball margin-right-5"></i> 4D Powerball




                                        </a>
                                    </li> -->
                                </ul>
                            </li>
                            <!-- 20191009 Kenneth add new tab menu for 50 & Beyond -->
                            <li class="main-nav">
                                <a class="main-nav-btn rw-url" href="#" onclick="ga('send','event','Top Navigations','Click','Home - 50 and Beyond')">50 &amp; Beyond</a>




                            </li>
                            <li class="main-nav">
                                <a class="main-nav-btn rw-url" href="#" onclick="ga('send','event','Top Navigations','Click','Home - Number Inspirations')">Number Inspirations



</a>
                            </li>
                            <li class="main-nav">
                                <a class="main-nav-btn rw-url" href="#" onclick="ga('send','event','Top Navigations','Click','Home - Draw Results')">Draw Results



</a>
                            </li>
                            <li class="main-nav">
                                <a class="main-nav-btn rw-url" href="#" onclick="ga('send','event','Top Navigations','Click','Home - Highlights')">News



</a>
                            </li>
                            <li class="main-nav">
                                <a class="main-nav-btn" data-toggle="collapse" href="#">MagnumCares



</a>
                                <ul id="sub-nav-magnumcares" role="menu" class="sub-nav-wrapper collapse">
                                    <li class="sub-nav">
                                        <a href="#" class="rw-url">
                                            <i class="fa fa-circle color-classic margin-right-5"></i> About MagnumCares




                                        </a>
                                    </li>
                                    <li class="sub-nav">
                                        <a href="#" class="rw-url">
                                            <i class="fa fa-circle color-classic margin-right-5"></i> Our Programmes




                                        </a>
                                    </li>
                                    <li class="sub-nav">
                                        <a href="#" class="rw-url">
                                            <i class="fa fa-circle color-classic margin-right-5"></i> Get In Touch




                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="main-nav">
                                <!-- 20211216 edited by Eddy where Help is replaced by Zendesk -->
                                <!--<a class="main-nav-btn rw-url" href="/help" onclick="ga('send','event','Top Navigations','Click','Home - Help')">Help



</a>-->
                                <a class="main-nav-btn rw-url" href="#" onclick="ga('send','event','Top Navigations','Click','Home - Help'); clickHelp(); return false;">Help



</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
    
            <!-- <div class="back-arrow">
                <a href="" id="NavArrowBack"><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
            </div> -->
        </header>
        <div class="fill-spacing"></div>
        


<!-- <div class='kentico-webzone'>
    <img id="p_lt_ctl39_pageplaceholder_p_lt_ctl00_ImgHeroBanner_ucEditableImage_imgImage" title="hero-banner" class="webpart-img-clipboard responsive-image" src="https://www.magnum4d.my/Magnum4d/media/images/banner/results/banner-results.jpg" alt="hero-banner" />
</div> -->

<div id='hero-banner' class="hero-banner" style="background-image: url(&quot;https://www.magnum4d.my/Magnum4d/media/images/banner/results/banner-results.jpg&quot;);">
    <div class="hero-banner-content">
    </div>
</div>

<section class="content-main">
        <div class="container" data-spy="affix" data-offset-top="350">
        <div class="page-tabs-wrapper">
            <div class="clearfix">
                <a class="page-tab rw-url col-xs-6 active" href="#" onclick="ga('send','event','Draw Results','Click','Past Draw Results')">
                    Past Draw Results




                </a>
                <a class="page-tab rw-url col-xs-6" href="#">
                    Winning History




                </a>
            </div>
        </div>
        <section id="filter-row" class="filter-row show-filter padding-top-10 padding-sm-top-30 padding-sm-bottom-30 padding-lg-vertical-30">
            <h3 class="filter-title clearfix">
                <button id="btn-show-filter" class="btn-result-filter btn-clear inner-vertical-mid" type="button">
                    <i class="fa fa-filter" aria-hidden="true"></i>
                    <span>Filter Results</span>
                    <i class="show-filter-indicator fa fa-chevron-down" aria-hidden="true"></i>
                </button>

                <div class="float-right hidden-xs">
                    <a href="#" class="btn-o btn-bordered margin-right-5" type="button" style="color: #333;">
                        Did I Win




                    </a>
                    <button class="btn-o btn-bordered" data-toggle="modal" data-target="#modal-where-to-claim" type="button">
                        Where to claim




                    </button>
                </div>
            </h3>
            <div id="result-filters" class="filter-wrapper">
            <div class="filter-form-wrapper inner-vertical-sm-bottom">
                    <!-- <div class="filter-group">
                        <label for="filter-latest" class="label">
                            Latest




                        </label>
                        <div class="select-control">
                            <select id="filter-latest" class="form-control">
                                <option value="">Select / 选择</option>
                                <option value="3">3 Draws / 3期</option>
                                <option value="6">6 Draws / 6期</option>
                                <option value="9">9 Draws / 9期</option>
                            </select>
                        </div>
                    </div>

                    <div class="text-sm-center inline-block-min-sm">
                        <span class="label-round grey align-bottom">
                            or




                        </span>
                    </div> -->
                    <div class="filter-group">
                        <label for="filter-date-from" class="label">
                            




                        </label>
                        <div class="form-group-icon">
                            <div class="select-control">
                                <input id="filter-date-from" class="form-control datepicker" data-date-end-date="0d"
                                   data-provide="datepicker" data-date-format="yyyy-mm-dd" readonly />
                            </div>
                            <label for="filter-date-from" class="form-icon">
                                <i class="fa fa-calendar"></i>
                            </label>
                        </div>
                    </div>
                    
                    <!-- Hiding the ToDate filter. using only From date for searching -->
                    <!--
                    <div class="text-sm-center inline-block-min-sm">
                        <span class="label-round align-bottom">
                            to




                        </span>
                    </div>

                    <div class="filter-group">
                        <label class="label-spacer"></label>
                        <div class="form-group-icon">
                            <div class="select-control">
                                <input id="filter-date-to" class="form-control datepicker" data-date-end-date="0d"
                                   data-provide="datepicker" data-date-format="yyyy-mm-dd" readonly />
                            </div>
                            <label for="filter-date-to" class="form-icon">
                                <i class="fa fa-calendar"></i>
                            </label>
                        </div>
                    </div>
                    -->

                </div>
            </div>
            <div class="visible-xs row">
                <div class="col-xs-6 col-p-0">
                    <!-- <button type="button" class="btn btn-primary block width-full" data-toggle="modal"
                            data-target="#modal-did-i-win">
                        Did I Win




                    </button> -->
                    <a href="#" class="btn btn-primary block width-full" onclick="ga('send','event','Draw Results','Click','Past Draw Results - Did I Win')">
                        Did I Win




                    </a>
                </div>
                <div class="col-xs-6 col-p-0">
                    <button type="button" class="btn btn-primary block width-full" data-toggle="modal"
                            data-target="#modal-where-to-claim">
                        Where To Claim




                    </button>
                </div>
            </div>
        </section>
    </div>

    <section class="page-wrapper">
        <div class="container">
            
          <div class="pastResultsCrossSellItems" style="display: none">
            <p class="pastResultsCrossSellBannerVal">~https://www.magnum4d.my/Magnum4d/media/images/banner/cross-sell/4d-game-old-cross-sell.jpg?ext=.jpg</p>
            <p class="pastResultsCrossSellTitleVal">Let 4D Classic Carry You towards Amazing Discoveries</p>
            <p class="pastResultsCrossSellDescriptionVal">Learn more about our all-time favourite classic game.</p>
            <p class="pastResultsCrossSellButtonCaptionVal">Find Out Now</p>
            <p class="pastResultsCrossSellButtonHrefVal">/en/4d-game</p>
          </div><div class="pastResultsCrossSellItems" style="display: none">
            <p class="pastResultsCrossSellBannerVal">~https://www.magnum4d.my/Magnum4d/media/images/banner/cross-sell/4d-jackpot-old-cross-sell.jpg?ext=.jpg</p>
            <p class="pastResultsCrossSellTitleVal">Enjoy Incredible Experiences with 4D Jackpot</p>
            <p class="pastResultsCrossSellDescriptionVal">Win multi-million-ringgit with a minimum buy of RM2!</p>
            <p class="pastResultsCrossSellButtonCaptionVal">Find Out Now</p>
            <p class="pastResultsCrossSellButtonHrefVal">/en/4d-jackpot</p>
          </div><div class="pastResultsCrossSellItems" style="display: none">
            <p class="pastResultsCrossSellBannerVal">~https://www.magnum4d.my/Magnum4d/media/images/banner/cross-sell/4d-mgold-cross-sell.jpg?ext=.jpg</p>
            <p class="pastResultsCrossSellTitleVal">Let's Toast to the Good Life with mGold</p>
            <p class="pastResultsCrossSellDescriptionVal">Our easy 6 numbers game lets you win cash prizes from only RM2!</p>
            <p class="pastResultsCrossSellButtonCaptionVal">Learn More</p>
            <p class="pastResultsCrossSellButtonHrefVal">/en/4d-mgold</p>
          </div><div class="pastResultsCrossSellItems" style="display: none">
            <p class="pastResultsCrossSellBannerVal">~https://www.magnum4d.my/Magnum4d/media/images/banner/cross-sell/Magnum-Life-cross-sell.jpg?ext=.jpg</p>
            <p class="pastResultsCrossSellTitleVal">Win RM1,000 Every Day for 20 Years</p>
            <p class="pastResultsCrossSellDescriptionVal">Live life a thousand times better with Magnum Life, our latest game.</p>
            <p class="pastResultsCrossSellButtonCaptionVal">Discover More</p>
            <p class="pastResultsCrossSellButtonHrefVal">/en/Magnum-Life</p>
          </div>
            <!-- <div id="spinner-result" class="loading-spinner">
                <div class="spinner-fold spinner-position-center">
                    <div class="sk-cube1 sk-cube"></div>
                    <div class="sk-cube2 sk-cube"></div>
                    <div class="sk-cube4 sk-cube"></div>
                    <div class="sk-cube3 sk-cube"></div>
                </div>
            </div> -->
            <!-- <div class="row">
                <div class="col-xs-12 text-center">
                    <div class="result-list-error">
                        <h3 class="cms-en hide">There is no draw on the selected date range, please search again.</h3>
                        <h3 class="cms-cn hide">您所输入的期限并没有开彩， 请另选其它日期。</h3>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6 col-lg-offset-0 col-lg-12">
                    <div class="result-by-date">
                        <div id = 'result-list' class="result-list clearfix">
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="padding-top-30 padding-bottom-30 text-center">
                        <button id="btnShowMore" type="button" class="btn-o btn-bordered-black" style = "display:none" >
                            Show More




                        </button>
                        <button id="btnShowNextDays" type="button" class="btn-o btn-bordered-black" style = "display:none" >
                        Show More




                        </button>
                    </div>
                </div>
            </div> -->
        







            <div class="row">
                <div class="col-xs-12 text-center">
                    <div class="result-list-error">
                        <h3 class="cms-en">There is no draw on the selected date range, please search again.</h3>
                        <h3 class="cms-cn hide">您所输入的期限并没有开彩， 请另选其它日期。</h3>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6 col-lg-offset-0 col-lg-12" v-for="item in lottos">
                   <div class="result-by-date">
                       <div id="result-list" class="result-list clearfix">
                           <div id="results-card-150-25" class="result-by-date card-wrapper margin-bottom-30">
                              <div class="row">
                                    <div class="col-xs-12 col-xs-p-5">
                                        <div class="heading-black-wrapper">
                                            <h3 class="heading-black text-uppercase" data-toggle="collapse"
                                                href="#150-25" aria-expanded="false">
                                                <span class="result-date-label yellow">
                                                    DRAW NO:
                                                </span>
                                                <span></span>
                                                <span class="date margin-left-5">{{item.showdate}}</span>
                                                <a class="btn-draw-show-hide">
                                                    <i class="fa  fa-angle-up" aria-hidden="true"></i>
                                                    <i class="fa  fa-angle-down" aria-hidden="true"></i>
                                                </a>
                                            </h3>
                                            <a href="#" class="btn-print float-right white font-22"><i
                                                    class="fa fa-print" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                              </div>
                              <div class="row">
                                   <div class="col-xs-12 col-xs-p-5">
                                       <div class="result-list clearfix item-list collapse in" id="150-25">
                                           <div class="d-flex">
                                               <!-- jackpot4D -->
                                               <div class="col-lg-4 col-p-0 result-block jackpot4D">
                                                   <!-- 4D Game -->
                                                   <div class="result-card yellow">
                                                       <h3 class="result-card-header" data-toggle="collapse"
                                                           href="#yellow-150-25" aria-expanded="true">
                                                           4D Classic 万能万字
                                                           <span class="header-icon float-right fa-stack hidden-lg">
                                                               <span class="i fa fa-chevron-down"></span>
                                                           </span>
                                                       </h3>
                                                       <div id="yellow-150-25"
                                                           class="result-card-content text-center collapse in">
                                                           <div class="result-content">
                                                               <span class="result-label">
                                                                   1ST PRIZE
                                                               </span>
                                                               <span class="result-number-lg btn-number-details"
                                                                   data-prize-type="1">{{ item.first }}</span>
                                                           </div>

                                                           <div class="result-content">
                                                               <span class="result-label">
                                                                   2ND PRIZE
                                                               </span>
                                                               <span class="result-number-lg btn-number-details"
                                                                   data-prize-type="2">{{ item.second }}</span>
                                                           </div>

                                                           <div class="result-content">
                                                               <span class="result-label">
                                                                   3RD PRIZE
                                                               </span>
                                                               <span class="result-number-lg btn-number-details"
                                                                   data-prize-type="3">{{ item.third }}</span>
                                                           </div>

                                                           <div class="result-content clearfix">
                                                               <div class="result-col col-xs-6">
                                                                   <span class="result-label block">
                                                                       SPECIAL
                                                                   </span>
                                                                   <div class="result-number-list">
                                                                       <span
                                                                           class="result-number col-xs-6 col-sm-p-5 col-md-p-15 btn-number-details special-prize-1"
                                                                           data-prize-type="s">{{item.special[1]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 col-sm-p-5 col-md-p-15 btn-number-details special-prize-2"
                                                                           data-prize-type="s">{{item.special[2]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 col-sm-p-5 col-md-p-15 btn-number-details special-prize-3"
                                                                           data-prize-type="s">{{item.special[3]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 col-sm-p-5 col-md-p-15 btn-number-details special-prize-4"
                                                                           data-prize-type="s">{{item.special[4]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 col-sm-p-5 col-md-p-15 btn-number-details special-prize-5"
                                                                           data-prize-type="s">{{item.special[5]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 col-sm-p-5 col-md-p-15 btn-number-details special-prize-6"
                                                                           data-prize-type="s">{{item.special[6]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 col-sm-p-5 col-md-p-15 btn-number-details special-prize-7"
                                                                           data-prize-type="s">{{item.special[7]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 col-sm-p-5 col-md-p-15 btn-number-details special-prize-8"
                                                                           data-prize-type="s">{{item.special[8]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 col-sm-p-5 col-md-p-15 btn-number-details special-prize-9"
                                                                           data-prize-type="s">{{item.special[9]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 col-sm-p-5 col-md-p-15 btn-number-details special-prize-10"
                                                                           data-prize-type="s">{{item.special[10]}}</span>
                                                                   </div>
                                                               </div>

                                                               <div class="result-col col-xs-6">
                                                                   <span class="result-label block">
                                                                       CONSOLATION
                                                                   </span>
                                                                   <div class="result-number-list clearfix">
                                                                       <span
                                                                           class="result-number col-xs-6 btn-number-details"
                                                                           data-prize-type="c">{{item.consolation[1]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 btn-number-details"
                                                                           data-prize-type="c">{{item.consolation[2]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 btn-number-details"
                                                                           data-prize-type="c">{{item.consolation[3]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 btn-number-details"
                                                                           data-prize-type="c">{{item.consolation[4]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 btn-number-details"
                                                                           data-prize-type="c">{{item.consolation[5]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 btn-number-details"
                                                                           data-prize-type="c">{{item.consolation[6]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 btn-number-details"
                                                                           data-prize-type="c">{{item.consolation[7]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 btn-number-details"
                                                                           data-prize-type="c">{{item.consolation[8]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 btn-number-details"
                                                                           data-prize-type="c">{{item.consolation[9]}}</span>
                                                                       <span
                                                                           class="result-number col-xs-6 btn-number-details"
                                                                           data-prize-type="c">{{item.consolation[10]}}</span>
                                                                   </div>
                                                               </div>
                                                           </div>

                                                       </div>
                                                   </div>

                                                   <!-- 4D Jackpot -->
                                                   <div class="result-card blue">
                                                       <h3 class="result-card-header" data-toggle="collapse" href="#blue-150-25" aria-expanded="true">
                                                           4D JACKPOT&nbsp;万字积宝
                                                           <span class="header-icon float-right fa-stack hidden-lg">
                                                               <span class="i fa fa-chevron-down"></span>
                                                           </span>
                                                       </h3>
                                                       <div id="blue-150-25"
                                                           class="result-card-content text-center collapse in">
                                                           <div class="result-content">
                                                               <span class="result-label block">
                                                                   4D JACKPOT 1 WINNING NUMBERS
                                                               </span>
                                                               <div class="clearfix">
                                                                   <div
                                                                       class="col-xs-4 col-p-5 col-sm-p-15 col-md-p-30">
                                                                       <div class="col-xs-12 col-p-0">
                                                                           <span
                                                                               class="result-number btn-number-details-jp"
                                                                               data-prize-type="1">{{item.first}}</span>
                                                                           <b class="margin-sm-side-2 number-pairing"><i
                                                                                   class="fa fa-plus"></i></b>
                                                                           <span
                                                                               class="result-number btn-number-details-jp"
                                                                               data-prize-type="2">{{item.second}}</span>
                                                                       </div>
                                                                       <div class="col-xs-12 col-p-0">
                                                                           <span
                                                                               class="result-number btn-number-details-jp"
                                                                               data-prize-type="2">{{item.second}}</span>
                                                                           <b class="margin-sm-side-2 number-pairing"><i
                                                                                   class="fa fa-plus"></i></b>
                                                                           <span
                                                                               class="result-number btn-number-details-jp"
                                                                               data-prize-type="1">{{item.first}}</span>
                                                                       </div>
                                                                   </div>
                                                                   <div
                                                                       class="col-xs-4 col-p-5 col-sm-p-15 col-md-p-30">
                                                                       <div class="col-xs-12 col-p-0">
                                                                           <span
                                                                               class="result-number btn-number-details-jp"
                                                                               data-prize-type="1">{{item.first}}</span>
                                                                           <b class="margin-sm-side-2 number-pairing"><i
                                                                                   class="fa fa-plus"></i></b>
                                                                           <span
                                                                               class="result-number btn-number-details-jp"
                                                                               data-prize-type="3">{{item.third}}</span>
                                                                       </div>
                                                                       <div class="col-xs-12 col-p-0">
                                                                           <span
                                                                               class="result-number btn-number-details-jp"
                                                                               data-prize-type="3">{{item.third}}</span>
                                                                           <b class="margin-sm-side-2 number-pairing"><i
                                                                                   class="fa fa-plus"></i></b>
                                                                           <span
                                                                               class="result-number btn-number-details-jp"
                                                                               data-prize-type="1">{{item.first}}</span>
                                                                       </div>
                                                                   </div>
                                                                   <div
                                                                       class="col-xs-4 col-p-5 col-sm-p-15 col-md-p-30">
                                                                       <div class="col-xs-12 col-p-0">
                                                                           <span
                                                                               class="result-number btn-number-details-jp"
                                                                               data-prize-type="2">{{item.second}}</span>
                                                                           <b class="margin-sm-side-2 number-pairing"><i
                                                                                   class="fa fa-plus"></i></b>
                                                                           <span
                                                                               class="result-number btn-number-details-jp"
                                                                               data-prize-type="3">{{item.third}}</span>
                                                                       </div>
                                                                       <div class="col-xs-12 col-p-0">
                                                                           <span
                                                                               class="result-number btn-number-details-jp"
                                                                               data-prize-type="3">{{item.third}}</span>
                                                                           <b class="margin-sm-side-2 number-pairing"><i
                                                                                   class="fa fa-plus"></i></b>
                                                                           <span
                                                                               class="result-number btn-number-details-jp"
                                                                               data-prize-type="2">{{item.second}}</span>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                               <a class="result-view-all" href="#"
                                                                   data-drawid="150-25">- View all 4D Jackpot winning
                                                                   numbers here



                                                                   -</a>
                                                           </div>

                                                           <div class="result-content text-left">
                                                               <span class="result-label">
                                                                   JACKPOT 1 PRIZE




                                                               </span>
                                                               <span
                                                                   class="result-price 4d-jackpot-1">RM{{ addComma(item.jackpot[1]) }}<span
                                                                       class="popover fade right in"
                                                                       role="tooltip"><span class="arrow"></span><span
                                                                           class="popover-content">Won</span></span>
                                                               </span>
                                                           </div>

                                                           <div class="result-content text-left">
                                                               <span class="result-label">
                                                                   JACKPOT 2 PRIZE




                                                               </span>
                                                               <span class="result-price 4d-jackpot-2">RM{{ addComma(item.jackpot[2]) }}<span
                                                                       class="popover fade right in"
                                                                       role="tooltip"><span class="arrow"></span><span
                                                                           class="popover-content">Won</span></span></span>
                                                           </div>
                                                           <div id="4d-result-footer-150-25" class="">
                                                               <h4 class="result-card-sub-header">
                                                                   Estimated Next Draw 4D Jackpot




                                                               </h4>
                                                               <div class="result-content text-left">
                                                                   <span class="result-label">
                                                                       JACKPOT 1 PRIZE




                                                                   </span>
                                                                   <span
                                                                       class="result-price-lg red jackpot-1">RM{{ addComma(item.jackpot2[1]) }}</span>
                                                               </div>
                                                               <div class="result-content text-left">
                                                                   <span class="result-label">
                                                                       JACKPOT 2 PRIZE




                                                                   </span>
                                                                   <span
                                                                       class="result-price-lg red jackpot-2">RM{{ addComma(item.jackpot2[2]) }}</span>
                                                               </div>
                                                           </div>
                                                       </div>
                                                       <div id="blue-empty-150-25" class="result-empty-wrapper">
                                                           <div class="result-content cms-en hide">
                                                               <span class="empty-title">
                                                                   4D JACKPOT was only
                                                                   <br>
                                                                   launched on 23 September 2009.
                                                               </span>
                                                               <span class="empty-desc">
                                                                   Find more about the
                                                                   <br>
                                                                   <a href="#">4D JACKPOT game</a>.
                                                               </span>
                                                           </div>
                                                           <div class="result-content cms-cn hide">
                                                               <span class="empty-title">
                                                                   万字积宝于2009年9月23日推出。
                                                               </span>
                                                               <span class="empty-desc">
                                                                   了解更多关于 <a href="#">万字积宝游戏。</a>
                                                               </span>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>

                                               <!-- Powerball -->
                                               <div class="col-lg-4 col-p-0 powerballResult"
                                                   style="display: none">
                                                   <!-- <div class="col-lg-4 col-p-0 powerballResult"> -->
                                                   <div class="result-card green">
                                                       <h3 class="result-card-header" data-toggle="collapse"
                                                           href="#green-150-25" aria-expanded="true">
                                                           4D POWERBALL 万字双宝




                                                           <span class="header-icon float-right fa-stack hidden-lg">
                                                               <span class="i fa fa-chevron-down"></span>
                                                           </span>
                                                       </h3>
                                                       <div id="green-150-25" class="result-card-content collapse in"
                                                           style="display: none;">
                                                           <div class="result-content-lg">
                                                               <div class="flex-row">
                                                                   <div class="result-label-lg">
                                                                       JACKPOT 1




                                                                   </div>
                                                                   <div class="result-label-xs align-self-right">
                                                                       TOP PRIZES + 2 POWERBALLS




                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <div class="result-content">
                                                               <div class="flex-row">
                                                                   <div class="result-label">
                                                                       <span class="block black">
                                                                           1ST PRIZE




                                                                       </span>
                                                                       <span class="block">
                                                                           100%




                                                                       </span>
                                                                   </div>
                                                                   <div class="align-self-right inner-vertical-mid">
                                                                       <span class="result-number">7486</span>
                                                                       <b class="margin-sm-side-2 number-pairing"><i
                                                                               class="fa fa-plus"></i></b>
                                                                       <b class="label-round green-border"></b>
                                                                       <b class="label-round green-border"></b>
                                                                       <b class="margin-sm-side-2 grey">
                                                                           or




                                                                       </b>
                                                                       <b class="label-round green-border"></b>
                                                                       <b class="label-round green-border"></b>
                                                                   </div>
                                                               </div>
                                                           </div>

                                                           <div class="result-content">
                                                               <div class="flex-row">
                                                                   <div class="result-label">
                                                                       <span class="block black">
                                                                           2nd prize




                                                                       </span>
                                                                       <span class="block">
                                                                           50%




                                                                       </span>
                                                                   </div>
                                                                   <div class="align-self-right inner-vertical-mid">
                                                                       <span class="result-number">6063</span>
                                                                       <b class="margin-sm-side-2 number-pairing"><i
                                                                               class="fa fa-plus"></i></b>
                                                                       <b class="label-round green-border"></b>
                                                                       <b class="label-round green-border"></b>
                                                                       <b class="margin-sm-side-2 grey">
                                                                           or




                                                                       </b>
                                                                       <b class="label-round green-border"></b>
                                                                       <b class="label-round green-border"></b>
                                                                   </div>
                                                               </div>
                                                           </div>

                                                           <div class="result-content divider">
                                                               <div class="flex-row">
                                                                   <div class="result-label">
                                                                       <span class="block black">
                                                                           3rd prize




                                                                       </span>
                                                                       <span class="block">
                                                                           25%




                                                                       </span>
                                                                   </div>
                                                                   <div class="align-self-right inner-vertical-mid">
                                                                       <span class="result-number">5495</span>
                                                                       <b class="margin-sm-side-2 number-pairing"><i
                                                                               class="fa fa-plus"></i></b>
                                                                       <b class="label-round green-border"></b>
                                                                       <b class="label-round green-border"></b>
                                                                       <b class="margin-sm-side-2 grey">
                                                                           or




                                                                       </b>
                                                                       <b class="label-round green-border"></b>
                                                                       <b class="label-round green-border"></b>
                                                                   </div>
                                                               </div>
                                                           </div>

                                                           <div class="result-content-lg">
                                                               <div class="result-label-lg">
                                                                   Jackpot 2




                                                               </div>
                                                           </div>

                                                           <div class="result-content-lg divider">
                                                               <div class="flex-row">
                                                                   <div class="result-label-xxs">
                                                                       <span class="block">
                                                                           Any




                                                                           <span class="black">
                                                                               Special




                                                                           </span>
                                                                           or




                                                                       </span>
                                                                       <span class="block">
                                                                           <span class="black">
                                                                               Consolation




                                                                           </span>
                                                                           Prize




                                                                       </span>
                                                                   </div>
                                                                   <div class="align-self-right inner-vertical-mid">
                                                                       <b class="margin-sm-right-10"><i
                                                                               class="fa fa-plus"></i></b>
                                                                       <b class="label-round green-border"></b>
                                                                       <b class="label-round green-border"></b>
                                                                       <b class="margin-sm-side-2 grey">
                                                                           or




                                                                       </b>
                                                                       <b class="label-round green-border"></b>
                                                                       <b class="label-round green-border"></b>
                                                                   </div>
                                                               </div>
                                                           </div>

                                                           <div class="result-content-lg">
                                                               <div class="flex-row">
                                                                   <span
                                                                       class="result-label margin-right-5 margin-sm-right-10">
                                                                       3rd prize




                                                                   </span>
                                                                   <b class="result-desc light-grey">
                                                                       Any 4D




                                                                       <span class="black">
                                                                           1st, 2nd or 3rd Prize




                                                                       </span>
                                                                   </b>
                                                               </div>
                                                           </div>
                                                           <div class="result-content-lg">
                                                               <div class="flex-row">
                                                                   <span
                                                                       class="result-label margin-right-5 margin-sm-right-10">
                                                                       4th prize




                                                                   </span>
                                                                   <b class="result-desc light-grey">
                                                                       Any 4D




                                                                       <span class="black">
                                                                           Special or Consolation Prize




                                                                       </span>
                                                                   </b>
                                                               </div>
                                                           </div>
                                                           <div class="result-content">
                                                               <div class="flex-row">
                                                                   <span
                                                                       class="result-label margin-right-5 margin-sm-right-10">
                                                                       5th prize




                                                                   </span>
                                                                   <div class="inner-vertical-mid">
                                                                       <b
                                                                           class="result-desc light-grey margin-right-2 margin-sm-right-5">
                                                                           Any




                                                                           <span class="black">
                                                                               ONE Powerball




                                                                           </span></b>
                                                                       <b class="label-round green-border"></b>
                                                                       <b class="margin-sm-side-2 light-grey">
                                                                           or




                                                                       </b>
                                                                       <b class="label-round green-border"></b>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <div class="result-content">
                                                               <div class="flex-row">
                                                                   <span class="result-label-xs margin-right-10">
                                                                       JACKPOT 1 PRIZE




                                                                   </span>
                                                                   <b class="result-price-lg grey">RM</b>
                                                               </div>
                                                           </div>
                                                           <div class="result-content">
                                                               <div class="flex-row">
                                                                   <span class="result-label-xs margin-right-10">
                                                                       JACKPOT 2 PRIZE




                                                                   </span>
                                                                   <b class="result-price-lg grey">RM</b>
                                                               </div>
                                                           </div>
                                                           <div id="powerball-result-footer-150-25" class="">
                                                               <h4 class="result-card-sub-header text-center">
                                                                   Estimated Next Draw 4D Powerball




                                                               </h4>
                                                               <div class="result-content text-left">
                                                                   <span class="result-label">
                                                                       JACKPOT 1 PRIZE




                                                                   </span>
                                                                   <span class="result-price-lg red jackpot-1">RMNot
                                                                       Available</span>
                                                               </div>
                                                               <div class="result-content text-left">
                                                                   <span class="result-label">
                                                                       JACKPOT 2 PRIZE




                                                                   </span>
                                                                   <span class="result-price-lg red jackpot-2">RMNot
                                                                       Available</span>
                                                               </div>
                                                           </div>

                                                       </div>
                                                       <div id="green-empty-150-25" class="result-empty-wrapper"
                                                           style="display: block;">
                                                           <div class="result-content cms-en hide">
                                                               <span class="empty-title">
                                                                   4D POWERBALL was only
                                                                   <br>
                                                                   launched on 24 January 2016.
                                                               </span>
                                                               <span class="empty-desc">
                                                                   Find more about the
                                                                   <br>
                                                                   <a href="#">4D POWERBALL game</a>.
                                                               </span>
                                                           </div>
                                                           <div class="result-content cms-cn hide">
                                                               <span class="empty-title">
                                                                   万字双宝于2016年1月24日推出。
                                                               </span>
                                                               <span class="empty-desc">
                                                                   了解更多关于 <a href="#">万字双宝游戏。</a>
                                                               </span>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>

                                               <!-- Jackpot Gold -->
                                               <div class="col-lg-4 col-p-0 result-block jackpotGold">
                                                   <div class="result-card red">
                                                       <h3 class="result-card-header" data-toggle="collapse"
                                                           href="#red-150-25" aria-expanded="true">
                                                           4D JACKPOT GOLD 黃金万字积宝




                                                           <span class="header-icon float-right fa-stack hidden-lg">
                                                               <span class="i fa fa-chevron-down"></span>
                                                           </span>
                                                       </h3>
                                                       <div id="red-150-25" class="result-card-content collapse in">
                                                           <div class="result-content">
                                                               <div class="text-right margin-top-5 margin-bottom-2"
                                                                   style="min-height: 44px;">
                                                                   <div style="display:block;">
                                                                       <img id="p_lt_ctl39_pageplaceholder_p_lt_ctl80_imgDrawCardJackpotGoldMGoldPicture_ucEditableImage_imgImage"
                                                                           src="https://www.magnum4d.my/Magnum4d/media/images/results-page/thumb-mgold.png"
                                                                           alt="">


                                                                   </div>
                                                               </div>
                                                               <div class="flex-row">
                                                                   <div class="result-label margin-right-30">
                                                                       Group 1




                                                                   </div>
                                                                   <div class="align-self-left inner-vertical-mid">
                                                                       <b
                                                                           class="label-square-lg border-right-none">{{item.first2.charAt(0)}}</b>
                                                                       <b
                                                                           class="label-square-lg border-right-none">{{item.first2.charAt(1)}}</b>
                                                                       <b
                                                                           class="label-square-lg border-right-none">{{item.first2.charAt(2)}}</b>
                                                                       <b
                                                                           class="label-square-lg border-right-none">{{item.first2.charAt(3)}}</b>
                                                                       <b
                                                                           class="label-square-lg border-right-none">{{item.first2.charAt(4)}}</b>
                                                                       <b class="label-square-lg">{{item.first2.charAt(5)}}</b>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <div class="result-content-xs">
                                                               <div class="result-label margin-top-5 margin-bottom-7">
                                                                   Group 2




                                                               </div>
                                                               <div class="flex-row align-baseline">
                                                                   <div
                                                                       class="align-self-right inner-vertical-mid margin-bottom-5">
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(0)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(1)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(2)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(3)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(4)}}</b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="margin-sm-side-3 grey">
                                                                           or




                                                                       </b>
                                                                       <b class="label-square-o border-right-none"></b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(1)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(2)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(3)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(4)}}</b>
                                                                       <b class="label-square">{{item.first2.charAt(5)}}</b>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <div class="result-content-xs">
                                                               <div class="result-label margin-top-5 margin-bottom-7">
                                                                   Group 3




                                                               </div>
                                                               <div class="flex-row align-baseline">
                                                                   <div
                                                                       class="align-self-right inner-vertical-mid margin-bottom-5">
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(0)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(1)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(2)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(3)}}</b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="margin-sm-side-3 grey">
                                                                           or




                                                                       </b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="label-square-o border-right-none"></b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(1)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(2)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(3)}}</b>
                                                                       <b class="label-square">{{item.first2.charAt(4)}}</b>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <div class="result-content-xs">
                                                               <div class="result-label margin-top-5 margin-bottom-7">
                                                                   Group 4




                                                               </div>
                                                               <div class="flex-row align-baseline">
                                                                   <div
                                                                       class="align-self-right inner-vertical-mid margin-bottom-5">
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(0)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(1)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(2)}}</b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="margin-sm-side-3 grey">
                                                                           or




                                                                       </b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="label-square-o border-right-none"></b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(2)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(3)}}</b>
                                                                       <b class="label-square">{{item.first2.charAt(4)}}</b>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <div class="result-content-xs">
                                                               <div class="result-label margin-top-5 margin-bottom-7">
                                                                   Group 5




                                                               </div>
                                                               <div class="flex-row align-baseline">
                                                                   <div
                                                                       class="align-self-right inner-vertical-mid margin-bottom-7">
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(0)}}</b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(1)}}</b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="margin-sm-side-3 grey">
                                                                           or




                                                                       </b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="label-square-o"></b>
                                                                       <b class="label-square-o border-right-none"></b>
                                                                       <b class="label-square border-right-none">{{item.first2.charAt(3)}}</b>
                                                                       <b class="label-square">{{item.first2.charAt(4)}}</b>
                                                                       <div class="text-center block margin-top-5">
                                                                           <b
                                                                               class="margin-sm-side-3 margin-left-pull-20 grey">
                                                                               or




                                                                           </b>
                                                                           <b class="label-square-o"></b>
                                                                           <b
                                                                               class="label-square-o border-right-none"></b>
                                                                           <b
                                                                               class="label-square border-right-none">{{item.first2.charAt(2)}}</b>
                                                                           <b
                                                                               class="label-square border-right-none">{{item.first2.charAt(3)}}</b>
                                                                           <b class="label-square-o"></b>
                                                                           <b class="label-square-o"></b>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <div class="result-content padding-top-20 padding-bottom-20">
                                                               <div class="flex-row">
                                                                   <div class="result-label margin-right-15">
                                                                       Golden Number




                                                                   </div>
                                                                   <div class="align-self-left inner-vertical-mid">
                                                                       <b class="label-square label-square-lg">{{item.second2.charAt(0)}}</b>
                                                                       <b class="label-square label-square-lg">{{item.second2.charAt(1)}}</b>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <div class="result-content">
                                                               <div class="flex-row">
                                                                   <span class="result-label-xs margin-right-10">
                                                                       JACKPOT 1 PRIZE




                                                                   </span>
                                                                   <b class="result-price-lg grey">RM{{ addComma(item.jackpot3[1]) }}</b>
                                                               </div>
                                                           </div>
                                                           <div class="result-content">
                                                               <div class="flex-row">
                                                                   <span class="result-label-xs margin-right-10">
                                                                       JACKPOT 2 PRIZE




                                                                   </span>
                                                                   <b class="result-price-lg grey">RM{{ addComma(item.jackpot3[2]) }}<span
                                                                           class="popover fade right in"
                                                                           role="tooltip"><span
                                                                               class="arrow"></span><span
                                                                               class="popover-content">Partially<br>Won</span></span></b>
                                                               </div>
                                                           </div>
                                                           <div id="jackpot-gold-result-footer-150-25" class="">
                                                               <h4 class="result-card-sub-header text-center">
                                                                   Estimated Next Draw 4D Jackpot Gold




                                                               </h4>
                                                               <div class="result-content text-left">
                                                                   <span class="result-label">
                                                                       JACKPOT 1 PRIZE




                                                                   </span>
                                                                   <span
                                                                       class="result-price-lg red jackpot-1">RM{{ addComma(item.jackpot4[1]) }}</span>
                                                               </div>
                                                               <div class="result-content text-left">
                                                                   <span class="result-label">
                                                                       JACKPOT 2 PRIZE




                                                                   </span>
                                                                   <span
                                                                       class="result-price-lg red jackpot-2">RM{{ addComma(item.jackpot4[2]) }}</span>
                                                               </div>
                                                           </div>
                                                       </div>
                                                       <div id="red-empty-150-25" class="result-empty-wrapper">
                                                           <div class="result-content cms-en hide">
                                                               <span class="empty-title">
                                                                   4D JACKPOT GOLD was only
                                                                   <br>
                                                                   launched on 8 November 2014.
                                                               </span>

                                                               <span class="empty-desc">
                                                                   Find more about the
                                                                   <br>
                                                                   <a href="#">4D JACKPOT GOLD game</a>.
                                                               </span>
                                                           </div>
                                                           <div class="result-content cms-cn hide">
                                                               <span class="empty-title">
                                                                   黄金万字积宝于2014年11月8日推出。
                                                               </span>

                                                               <span class="empty-desc">
                                                                   了解更多关于 <a href="#">黄金万字积宝游戏。</a>
                                                               </span>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>

                                               <!-- Magnum Life -->
                                               <div class="col-lg-4 col-p-0 result-block magnum-life"
                                                   style="display: flex">
                                                   <!-- <div class="col-lg-4 col-p-0 result-block magnum-life"> -->
                                                   <div class="result-card green">
                                                       <h3 class="result-card-header" data-toggle="collapse"
                                                           href="#orange-150-25" aria-expanded="true">
                                                           Magnum Life 万能天天彩&nbsp; &nbsp;




                                                           <span class="header-icon float-right fa-stack hidden-lg">
                                                               <span class="i fa fa-chevron-down"></span>
                                                           </span>
                                                       </h3>
                                                       <div id="orange-150-25" class="result-card-content collapse in">
                                                           <div class="result-content">
                                                               <div class="magnum-life-block text-center">
                                                                   <div class="result-label block">WINNING NUMBERS



                                                                   </div>
                                                                   <div class="winning-numbers">
                                                                       <b>01</b>
                                                                       <b>06</b>
                                                                       <b>07</b>
                                                                       <b>24</b>
                                                                       <b>25</b>
                                                                       <b>29</b>
                                                                       <b>30</b>
                                                                       <b>32</b>
                                                                   </div>
                                                               </div>
                                                               <div class="magnum-life-block text-center margin-top-10">
                                                                   <div class="result-label block">bonus NUMBERS



                                                                   </div>
                                                                   <div class="winning-numbers bonus-number">
                                                                       <b>23</b>
                                                                       <b>34</b>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <div id="magnumLife-result-footer-150-25"
                                                               class="result-footer">
                                                               <h4 class="result-card-sub-header text-center">PRize
                                                                   amount



                                                               </h4>
                                                               <div class="result-content text-left">
                                                                   <span class="result-label">grand Prize



                                                                   </span>
                                                                   <span class="result-price-lg red">rm1,000 every day



                                                                       <br><span class="fontSize14">for 20 years



                                                                       </span></span>
                                                               </div>
                                                               <div class="result-content text-left">
                                                                   <span class="result-label">2nd prize



                                                                   </span>
                                                                   <span class="result-price-lg red">rm1,000 every day



                                                                       <br><span class="fontSize14">for 100 days



                                                                           <span class="popover fade right in"
                                                                               role="tooltip"><span
                                                                                   class="arrow"></span><span
                                                                                   class="popover-content"
                                                                                   align="center">Won</span></span></span></span>
                                                               </div>
                                                           </div>
                                                       </div>
                                                       <!-- 20200930 Kenneth add this for magnum life empty result notice -->
                                                       <div id="orange-empty-150-25" class="result-empty-wrapper">
                                                           <div class="result-content cms-en hide">
                                                               <span class="empty-title">
                                                                   Magnum Life was only
                                                                   <br>
                                                                   launched on 24th April 2018.
                                                               </span>
                                                               <span class="empty-desc">
                                                                   Find more about the
                                                                   <br>
                                                                   <a href="#">MAGNUM LIFE game</a>.
                                                               </span>
                                                           </div>
                                                           <div class="result-content cms-cn hide">
                                                               <span class="empty-title">
                                                                   万能天天彩是于2018年4月24日推出。
                                                               </span>
                                                               <span class="empty-desc">
                                                                   了解更多关于 <a href="#">万能天天彩游戏。</a>
                                                               </span>
                                                           </div>
                                                       </div>
                                                       <!--                                                            -->
                                                   </div>

                                                   <div class="result-card cross-sell">
                                                       <div>
                                                           <img :src="randomImage()"
                                                               alt="Magnum Life - Cross-Sell Banner">
                                                       </div>
                                                       <div class="cross-sell-copy">
                                                           <h3>Let 4D Classic Carry You towards Amazing Discoveries</h3>
                                                           <p>Learn more about our all-time favourite classic game.</p>
                                                       </div>
                                                       <div class="cross-sell-download">
                                                           <!--a href="https://play.google.com/store/apps/details?id=my.magnum.magnum4d&utm_source=landing&utm_medium=www&utm_campaign=PartBadge" target="_blank">
                                                  <img src="https://www.magnum4d.my/Magnum4d/media/images/google-play.png" alt="Google Play">
                                              </a>
                                              <a href="https://itunes.apple.com/my/app/magnum-4d-live/id763361098?mt=8" target="_blank">
                                                  <img src="https://www.magnum4d.my/Magnum4d/media/images/app-store.png" alt="App Store">
                                              </a-->

                                                           <!-- Scan now button -->
                                                           <a href="#" data-role-deeplink="true" id="btnScanNow"
                                                               class="btn btn-primary">
                                                               Find Out Now
                                                           </a>
                                                       </div>
                                                   </div>
                                               </div>
                                               <!-- End Magnum Life -->

                                               <!-- Magnum Life - Cross-sell Mobile -->
                                               <div class="col-lg-4 col-p-0 result-block magnum-life magnum-life-cross-sell">
                                                   <!-- <div class="col-lg-4 col-p-0 result-block magnum-life magnum-life-cross-sell"> -->
                                                   <div class="result-card cross-sell">
                                                       <div class="img">
                                                           <img src="//www.magnum4d.my/Magnum4d/media/images/banner/cross-sell/4d-game-old-cross-sell.jpg?ext=.jpg"
                                                               alt="Magnum Life - Cross-Sell Banner">
                                                       </div>
                                                       <div class="cross-sell-copy">
                                                           <h3>Let 4D Classic Carry You towards Amazing Discoveries</h3>
                                                           <p>Learn more about our all-time favourite classic game.</p>
                                                       </div>
                                                       <div class="cross-sell-download">
                                                           <!-- Scan now button -->
                                                           <a href="#" data-role-deeplink="true" id="btnScanNow"
                                                               class="btn btn-primary">
                                                               Find Out Now
                                                               <!--Scan Now



          -->
                                                           </a>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="padding-top-30 padding-bottom-30 text-center">
                        <button id="btnShowMore" type="button" class="btn-o btn-bordered-black" style="">
                            Show More
                        </button>
                    </div>
                </div>
            </div>















          </div>
    </section>

    <section class="result-bottom-info text-center">
        <h2 class="title text-uppercase">Play Now and Win Big!



</h2>
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-4">
                    <div class="result-bottom-info-item">
                        <div class="img"><img id="p_lt_ctl39_pageplaceholder_p_lt_ctl17_imgFeaturedResultInfoSection1_ucEditableImage_imgImage" class="margin-bottom-15" src="https://www.magnum4d.my/Magnum4d/media/images/results-page/did-i-win.png" alt="" />

</div>
                        <div class="desc">
                            <h3>Did I Win?



</h3>
                            <p>View the latest results to see if your 4D number won big!



</p>
                            <div class="result-bottom-info-item-cta">
                                <a class="primary" href="#">See Results



</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-4">
                    <div class="result-bottom-info-item">
                        <div class="img"><img id="p_lt_ctl39_pageplaceholder_p_lt_ctl21_imgFeaturedResultInfoSection2_ucEditableImage_imgImage" class="margin-bottom-15" src="https://www.magnum4d.my/Magnum4d/media/images/results-page/mobile-app.png" alt="" />

</div>
                        <div class="desc">
                            <h3>Download Our Mobile App



</h3>
                            <p>Playing and winning our games are now at your fingertips!



</p>
                            <div class="result-bottom-info-item-cta">
                                <a class="primary" href="#" target="_blank">
                                  Google Play



</a> 
                                <span class="margin-side-2">/</span> 
                                <a class="primary" href="#" target="_blank">
                                  App Store



</a>
                                <span class="margin-side-2">/</span> 
                                <a class="primary" href="#" target="_blank">Huawei App Gallery</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-4">
                    <div class="result-bottom-info-item">
                        <div class="img"><img id="p_lt_ctl39_pageplaceholder_p_lt_ctl26_imgFeaturedResultInfoSection3_ucEditableImage_imgImage" class="margin-bottom-15" src="https://www.magnum4d.my/Magnum4d/media/images/results-page/outlets.png" alt="" />

</div>
                        <div class="desc">
                            <h3>Find an Outlet



</h3>
                            <p>Search for the nearest Magnum 4D outlet near you!



</p>
                            <div class="result-bottom-info-item-cta">
                                <a class="primary rw-url" href="#">Locate Outlet



</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>


        <footer>
            <!--<section id="how-to-play-list" class="how-to-play-list text-center">
                <h2 class="title text-uppercase">
                    Learn How to Play Our Exciting Games!




                </h2>
                <div class="row">
                    <a id="how-to-play-4d" class="how-to-play-item rw-url col-xs-12 col-sm-6 col-md-3 game-4d" href="/4d-game" onclick="ga('send','event','Footer Game Cross-sell Buttons','Click','Home - 4D')">
                        
                    </a>
                    <a id="how-to-play-jackpot" class="how-to-play-item rw-url col-xs-12 col-sm-6 col-md-3 game-jackpot" href="/4d-jackpot" onclick="ga('send','event','Footer Game Cross-sell Buttons','Click','Home - 4D Jackpot')">
                        
                    </a>
                    <a id="how-to-play-jackpot-gold" class="how-to-play-item rw-url col-xs-12 col-sm-6 col-md-3 game-jackpotgold" href="#" onclick="ga('send','event','Footer Game Cross-sell Buttons','Click','Home - 4D Jackpot Gold')">
                        
                    </a>
                    <a id="how-to-play-powerball" class="how-to-play-item rw-url col-xs-12 col-sm-6 col-md-3 game-powerball" href="/4d-powerball" onclick="ga('send','event','Footer Game Cross-sell Buttons','Click','Home - 4D Powerball')">
                        
                    </a>
                </div>
            </section>-->
            <div class="relative footer-restyle">
                <div class="footer-blocks">
                    <div class="container">
                        <div class="row footer-block-top">
                            <div class="col-xs-12 col-md-4 careline-social">
                                <div class="footer-block">
                                    <h3>Customer Service



</h3>
                                    <ul class="footer-items clearfix">
                                        <li class="footer-item contact">
                                            <i class="fa fa-phone" aria-hidden="true"></i>
                                            603 9212 xxxx




                                            <div class="careline-margin">&nbsp; (9:00am to 7:00pm)&nbsp;



</div>
                                        </li>
                                        <li class="footer-item store-locator">
                                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                                            <a id="p_lt_ctl48_linkStoreLocator_btnElem_hyperLink" class="rw-url" href="#"><span id="p_lt_ctl48_linkStoreLocator_btnElem_lblText">Store Locator</span></a>

                                        </li>
                                      <!-- 20211216 edited by Eddy where Feedback Form should be accessed from Zendesk -->
                                      <!--
                                        <li class="footer-item feedback-form">
                                            <i class="fa fa-envelope" aria-hidden="true"></i>
                                            <a id="p_lt_ctl49_linkFeedbackForm_btnElem_hyperLink" onclick="ga(&#39;send&#39;,&#39;event&#39;,&#39;Footer&#39;,&#39;Submit&#39;,&#39;Feedback Form&#39;)" href="/feedback-form"><span id="p_lt_ctl49_linkFeedbackForm_btnElem_lblText">Feedback Form</span></a>

                                        </li>
                                      -->
                                    </ul>
                                </div>
                                <div class="footer-block visible-xs visible-sm social-mobile">
                                    <h3>Social



</h3>
                                    <ul class="social-items social clearfix">
                                        <li class="social-item facebook">
                                            <a href="#" target="_blank" onclick="ga('send','event','Footer','Click','Social CTA Button - Facebook')">
                                                <span class="fa-stack fa-lg">
                                                  <i class="fa fa-square fa-stack-2x"></i>
                                                  <i class="fa fa-facebook fa-stack-1x fa-inverse"></i>
                                                </span>
                                            </a>
                                        </li>
                                        <li class="social-item instagram">
                                            <a href="#" target="_blank" onclick="ga('send','event','Footer','Click','Social CTA Button - Instagram')">
                                                <span class="fa-stack fa-lg">
                                                  <!-- <i class="fa fa-square fa-stack-2x"></i>
                                                  <i class="fa fa-instagram fa-stack-1x fa-inverse"></i> -->
    
                                                </span>
                                            </a>
                                        </li>
                                        <li class="social-item youtube">
                                            <a href="#" target="_blank" onclick="ga('send','event','Footer','Click','Social CTA Button - YouTube')">
                                                <span class="fa-stack fa-lg">
                                                  <i class="fa fa-square fa-stack-2x"></i>
                                                  <i class="fa fa-youtube fa-stack-1x fa-inverse"></i>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <div class="footer-block">
                                    <h3>Email



</h3>
                                    <ul class="footer-items clearfix">
                                        <li class="footer-item email">
                                            <i class="fa fa-envelope" aria-hidden="true"></i>
                                            <a id="p_lt_ctl52_linkMainEmailAddress_btnElem_hyperLink" href="mailto:webmaster@magnum.my"><span id="p_lt_ctl52_linkMainEmailAddress_btnElem_lblText">webmaster@magnum.my</span></a>

                                        </li>
                                    </ul>
                                </div> -->
                            </div>
                            <div class="col-xs-12 col-md-4">
                                <div class="footer-block">
                                    <h3>Newsletter Sign Up



</h3>
                                    <ul class="footer-items clearfix">
                                        <li class="footer-item newsletter">
                                            <div class="form-inline">
                                                <div class="form-group newsletter-form margin-bottom-15 margin-sm-bottom-0">
                                                    <input id="txtSignUpNewsLetterEmail" type="text" class="form-control" placeholder="Example@email.com"
                                                        data-validation="required,email"> 
                                                    <!--<input id="txtSignUpNewsLetterEmail" type="text" class="form-control" placeholder="Example@email.com">-->
                                                </div>
                                                <button id="btnSignUpNewsletter" type="submit" class="btn btn-primary btn-process" onclick="ga('send','event','Footer','Subscribe','Newsletter Sign Up')">
                                                    Sign Up




                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="footer-block hidden-xs hidden-sm">
                                    <h3>Social



</h3>
                                    <ul class="social-items social clearfix">
                                        <li class="social-item facebook">
                                            <a href="#" target="_blank" onclick="ga('send','event','Footer','Click','Social CTA Button - Facebook')">
                                                <span class="fa-stack fa-lg">
                                                  <i class="fa fa-square fa-stack-2x"></i>
                                                  <i class="fa fa-facebook fa-stack-1x fa-inverse"></i>
                                                </span>
                                            </a>
                                        </li>
                                        <li class="social-item instagram">
                                            <a href="#" target="_blank" onclick="ga('send','event','Footer','Click','Social CTA Button - Instagram')">
                                                <span class="fa-stack fa-lg">
                                                  <!-- <i class="fa fa-square fa-stack-2x"></i>
                                                  <i class="fa fa-instagram fa-stack-1x fa-inverse"></i> -->
                                                </span>
                                            </a>
                                        </li>
                                        <li class="social-item youtube">
                                            <a href="#" target="_blank" onclick="ga('send','event','Footer','Click','Social CTA Button - Instagram')">
                                                <span class="fa-stack fa-lg">
                                                  <i class="fa fa-square fa-stack-2x"></i>
                                                  <i class="fa fa-youtube fa-stack-1x fa-inverse"></i>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-4 downloadApp-receiveEmail">
                                <div class="footer-block">
                                    <h3>Download Our App



</h3>
                                    <ul class="app-items clearfix">
                                        <li class="app-item android">
                                            <a href="#" target="_blank" onclick="ga('send','event','Footer','Download','Android Download')">
                                                <i class="fa fa-3x fa-android"></i>
                                            </a>
                                        </li>
                                        <li class="app-item ios">
                                            <a href="#" target="_blank" onclick="ga('send','event','Footer','Download','iOS Download')">
                                                <i class="fa fa-3x fa-apple"></i>
                                            </a>
                                        </li>
                                        <li class="app-item huawei">
                                            <a href="#" target="_blank" onclick="ga('send','event','Footer','Download','Huawei Download')">
                                                <img src="https://mktuat.magnum4d.my/Magnum4d/media/images/footer/icon-huawei_bnw.png" 
                                              onmouseover="this.src='https://mktuat.magnum4d.my/Magnum4d/media/images/footer/icon-huawei_colour.png'" 
                                              onmouseout="this.src='https://mktuat.magnum4d.my/Magnum4d/media/images/footer/icon-huawei_bnw.png'">
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="footer-block">
                                    <!-- <h3>Receive 4D Results



</h3> -->
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-cert">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12">
                                <ul>
                                    <li><h3>Global Recognition



</h3></li>
                                </ul>
                                <ul class="sponsor-logos">
                                    <li><a id="p_lt_ctl61_linkISOWLASCS_btnElem_hyperLink" class="rw-url" href="#"><img id="p_lt_ctl61_linkISOWLASCS_btnElem_image" src="https://www.magnum4d.my/Magnum4d/media/images/footer/wla-new.jpg?ext=.jpg" /><span id="p_lt_ctl61_linkISOWLASCS_btnElem_lblText"></span></a>
</li>
                                    <li><a id="p_lt_ctl62_linkWLA_btnElem_hyperLink" href="#" target="_blank"><img id="p_lt_ctl62_linkWLA_btnElem_image" src="https://www.magnum4d.my/Magnum4d/media/images/footer/biz-trust.png?ext=.png" /><span id="p_lt_ctl62_linkWLA_btnElem_lblText"></span></a>
</li>
                                    <li><a id="p_lt_ctl63_linkAPIA_btnElem_hyperLink" class="rw-url" href="#"><img id="p_lt_ctl63_linkAPIA_btnElem_image" src="https://www.magnum4d.my/Magnum4d/media/images/footer/cert-apia.jpg?ext=.jpg" /><span id="p_lt_ctl63_linkAPIA_btnElem_lblText"></span></a>
</li>
                                    <li><a id="p_lt_ctl64_linkPlayResponsibly_btnElem_hyperLink" class="rw-url" href="#"><img id="p_lt_ctl64_linkPlayResponsibly_btnElem_image" src="https://www.magnum4d.my/Magnum4d/media/images/footer/cert-play-responsibly.jpg?ext=.jpg" /><span id="p_lt_ctl64_linkPlayResponsibly_btnElem_lblText"></span></a>
</li>
                                </ul>
                                <!-- <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-links">
                        <div class="container">
                            <div class="row">
                                <div class="col-xs-12">
                                    <!-- <div class="footer-copyright">
                                        <p>Copyright © 1998 - 2017. Magnum Corporation Sdn. Bhd. (8272-D). All Rights Reserved.



</p>
                                    </div> -->
                                    <div class="footer-links-item">
                                        <ul>
                                            <li>&copy; 2024



<a id="p_lt_ctl71_linkFooterLinksItem3_btnElem_hyperLink" href="#" target="_blank"><span id="p_lt_ctl71_linkFooterLinksItem3_btnElem_lblText">Magnum Corporation Sdn Bhd (8272-D)</span></a>
</li>
                                            <li><a id="p_lt_ctl72_linkFooterLinksItem2_btnElem_hyperLink" class="rw-url" href="#"><span id="p_lt_ctl72_linkFooterLinksItem2_btnElem_lblText">Privacy</span></a>
</li>
                                            <li><a id="p_lt_ctl73_linkFooterLinksItem1_btnElem_hyperLink" class="rw-url" href="#"><span id="p_lt_ctl73_linkFooterLinksItem1_btnElem_lblText">Disclaimer &amp; Terms of Usage</span></a>
</li>
    
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="footer-pattern">
                    <img src="https://www.magnum4d.my/Magnum4d/media/images/footer/element-goldbox.jpg" alt="">
                </div>
            </div>
        </footer>
    
        <!-- Modal Box -->
        <div id="modal-did-i-win" class="modal-did-i-win modal modal-prior fade" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">DID I WIN?



</h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="download-app-card">
                            <p>Download our Magnum 4D app to check your results instantly.



</p>
                            <div class="clearfix">
                                <a class="col-xs-12 col-sm-6 col-p-5" #">
                                    <img class="img-responsive margin-auto margin-sm-right-0" src="https://www.magnum4d.my/Magnum4d/media/images/google-play.png" alt="Google Play">
                                </a>
                                <a class="col-xs-12 col-sm-6 col-p-5 margin-top-10 margin-sm-top-0" href="#">
                                    <img class="img-responsive margin-auto margin-sm-left-0" src="https://www.magnum4d.my/Magnum4d/media/images/app-store.png" alt="App Store">
                                </a>
                            </div>
                        </div>
                        <div class="check-your-number margin-top-25">
                            <h4 class="modal-body-title">CHECK IF YOUR NUMBER HAS WON!



</h4>
                            <hr class="modal-body-divider">
    
                            <div id="check-number-form" class="check-number-form">
                                <div class="form-row clearfix row-0 middle-xs">
                                    <div class="col-xs-12 col-sm-3 col-p-0">
                                        <label for="game-date" class="label">Date



</label>
                                    </div>
                                    <div class="form-group col-xs-12 col-sm-9 col-p-0">
                                        <div class="form-group-icon">
                                            <div class="select-control">
                                                <input class="form-control datepicker" id="game-date"
                                                   data-validation="required,date" data-validation-format="dd/mm/yyyy"
                                                       data-date-end-date="-1d" data-provide="datepicker" data-date-format="dd/mm/yyyy"/>
                                            </div>
                                            <label for="game-date" class="form-icon">
                                                <i class="fa fa-calendar"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row clearfix row-0 middle-xs">
                                    <div class="col-xs-12 col-sm-3 col-p-0">
                                        <label for="did-i-win-game-type" class="label">Game



</label>
                                    </div>
                                    <div class="form-group col-xs-12 col-sm-9 col-p-0">
                                        <div class="select-control">
                                            <select id="did-i-win-game-type" class="form-control"
                                                    data-validation="required">
                                                <option value="4d" selected>4D Game / 万能万字</option>
                                                <option value="4d Jackpot">4D Jackpot / 万字积宝</option>
                                                <option value="4d Jackpot Gold">4D Jackpot Gold / 黃金万字积宝</option>
                                                <option value="4d Powerball">4D Powerball / 万字双宝</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="did-i-win-game-type-wrapper active" data-type="4d">
                                    <div class="form-row clearfix row-0 middle-xs">
                                        <div class="col-xs-12 col-sm-3 col-p-0">
                                            <label for="did-i-win-4d-number" class="label">Number



</label>
                                        </div>
                                        <div class="form-group col-xs-12 col-sm-9 col-p-0">
                                            <input type="text" max="9999" maxlength="4" class="form-control num-val" id="did-i-win-4d-number"
                                                data-validation="required,number,length" data-validation-length="4">
                                        </div>
                                    </div>
                                    <div class="form-row clearfix row-0 middle-xs">
                                        <div class="col-xs-12 col-sm-3 col-p-0">
                                            <label for="did-i-win-4d-bet-big" class="label">Big



</label>
                                        </div>
                                        <div class="col-xs-12 col-sm-9 col-p-0 clearfix">
                                            <div class="form-group col-xs-12 col-sm-5 col-p-0 clearfix">
                                                <input type="text" id="did-i-win-4d-bet-big" class="form-control"
                                                    data-validation="required,number,length" data-validation-length="max3" maxlength="3">
                                            </div>
                                            <div class="col-xs-12 col-sm-7 col-p-0 clearfix row-0 middle-xs">
                                                <div class="col-xs-12 col-sm-4 col-p-0">
                                                    <label for="did-i-win-4d-bet-small" class="label">Small



</label>
                                                </div>
                                                <div class="form-group col-xs-12 col-sm-8 col-p-0">
                                                    <input type="text" id="did-i-win-4d-bet-small" class="form-control"
                                                        data-validation="required,number,length" data-validation-length="max3" maxlength="3">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="did-i-win-game-type-wrapper" data-type="4d Jackpot">
                                    <div class="form-row clearfix row-0 middle-xs">
                                        <div class="col-xs-12 col-sm-3 col-p-0">
                                            <label for="did-i-win-4d-jackpot-number-1" class="label">Number 1



</label>
                                        </div>
                                        <div class="col-xs-12 col-sm-9 col-p-0">
                                            <div class="form-group col-xs-12 col-sm-5 col-p-0 clearfix">
                                                <input type="text" class="form-control" id="did-i-win-4d-jackpot-number-1" maxlength="4"
                                                    data-validation="required,length" data-validation-length="4">
                                            </div>
                                            <div class="col-xs-12 col-sm-7 col-p-0 clearfix row-0 middle-xs">
                                                <div class="col-xs-12 col-sm-4 col-p-0 text-right">
                                                    <label for="did-i-win-4d-jackpot-number-1-type" class="label">Type



</label>
                                                </div>
                                                <div class="form-group col-xs-12 col-sm-8 col-p-0">
                                                    <select class="form-control" id="did-i-win-4d-jackpot-number-1-type"
                                                        data-validation="required">
                                                        <option value="S">Straight / 单一号码</option>
                                                        <option value="P">Permutate / 包字</option>
                                                        <option value="R">Roll / 打字</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row clearfix row-0 middle-xs">
                                        <div class="col-xs-12 col-sm-3 col-p-0">
                                            <label for="did-i-win-4d-jackpot-number-2" class="label">Number 2



</label>
                                        </div>
                                        <div class="col-xs-12 col-sm-9 col-p-0">
                                            <div class="form-group col-xs-12 col-sm-5 col-p-0 clearfix">
                                                <input type="text" class="form-control" id="did-i-win-4d-jackpot-number-2" maxlength="4"
                                                    data-validation="required,length" data-validation-length="4">
                                            </div>
                                            <div class="col-xs-12 col-sm-7 col-p-0 clearfix row-0 middle-xs">
                                                <div class="col-xs-12 col-sm-4 col-p-0 text-right">
                                                    <label for="did-i-win-4d-jackpot-number-2-type" class="label">Type



</label>
                                                </div>
                                                <div class="form-group col-xs-12 col-sm-8 col-p-0">
                                                    <select class="form-control" id="did-i-win-4d-jackpot-number-2-type"
                                                        data-validation="required">
                                                        <option value="S">Straight / 单一号码</option>
                                                        <option value="P">Permutate / 包字</option>
                                                        <option value="R">Roll / 打字</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row clearfix row-0 middle-xs">
                                        <div class="col-xs-12">
                                            <p>*For Roll input, You must enter a 4-digit Number with an R representing the rolled digit, e.g. 912R, R127 or 6R33.</p>





                                        </div>
                                    </div>
                                </div>
                                <div class="did-i-win-game-type-wrapper" data-type="4d Jackpot Gold">
                                    <div class="form-row clearfix row-0 middle-xs">
                                        <div class="col-xs-12 col-sm-3 col-p-0">
                                            <label for="did-i-win-4d-jackpot-gold-trifecta-number" class="label">Number



</label>
                                        </div>
                                        <div class="col-xs-12 col-sm-9 col-p-0">
                                            <div class="row-5">
                                                <div class="col-xs-8 col-p-5">
                                                    <input type="text" class="form-control" id="did-i-win-4d-jackpot-gold-trifecta-number" maxlength="6"
                                                        data-validation="required,number,length" data-validation-length="6">
                                                </div>
                                                <div class="form-group col-xs-4 col-p-5">
                                                    <input type="text" class="form-control" id="did-i-win-4d-jackpot-gold-golden-number" maxlength="2"
                                                        data-validation="required,number,length" data-validation-length="2" data-validation-allowing="range[0;19]">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="did-i-win-game-type-wrapper" data-type="4d Powerball">
                                    <div class="form-row clearfix row-0 middle-xs">
                                        <div class="col-xs-12 col-sm-3 col-p-0">
                                            <label for="did-i-win-4d-powerball-number" class="label">Number



</label>
                                        </div>
                                        <div class="col-xs-12 col-sm-9 col-p-0">
                                            <div class="row-5">
                                                <div class="form-group col-xs-6 col-p-5">
                                                    <input type="text" class="form-control" id="did-i-win-4d-powerball-number" maxlength="4"
                                                        data-validation="required,number,length" data-validation-length="4">
                                                </div>
                                                <div class="form-group col-xs-3 col-p-5">
                                                    <input type="text" class="form-control" id="did-i-win-4d-powewrball-golden-number-1" maxlength="2"
                                                        data-validation="required,number,length" data-validation-length="2">
                                                </div>
                                                <div class="form-group col-xs-3 col-p-5">
                                                    <input type="text" class="form-control" id="did-i-win-4d-powewrball-golden-number-2" maxlength="2"
                                                        data-validation="required,number,length" data-validation-length="2">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="form-row text-right margin-top-25">
                                    <button id = "btnSubmitDidIWin" class="btn btn-primary" type="submit">Submit



</button>
                                </div>
                            </div>
                        </div>
                        <div id = "spinner-did-i-win" class="loading-spinner hidden">
                            <div class="spinner-fold spinner-position-center">
                                <div class="sk-cube1 sk-cube"></div>
                                <div class="sk-cube2 sk-cube"></div>
                                <div class="sk-cube4 sk-cube"></div>
                                <div class="sk-cube3 sk-cube"></div>
                            </div>
                        </div>
                        <div id = "result-did-i-win" class="check-number-result margin-top-25">
                            <h4 class="modal-body-title">Result



</h4>
                            <hr class="modal-body-divider">
                            <p class="modal-body-sub-title margin-bottom-5">You have



 <span id = "did-i-win-winCount"></span> winning number(s)



 <span id = "did-i-win-drawID" class="red"></span></p>
    
                            <table id = "did-i-win-resultList" class="table grey margin-bottom-2">
                            </table>
                            <!-- <p id="did-i-win-resultList-hint" style="display: none;"><span class="red">*</span>Please claim your prize at any Magnum 4D outlet in Malaysia.



</p> -->
                        </div>
    
                        <div class="row-5 text-center margin-top-10">
                            <div class="col-xs-12 col-sm-6 col-p-5">
                                <div class="locate-nearby-outlets-card">
                                    <h4>Just drop in at any Magnum 4D outlet to claim your prize.



</h4>
                                    <a href="#" id="btn-open-where-to-claim" class="btn btn-primary">Locate Nearby Outlets



</a>
                                </div>
                            </div>
                            <br class="visible-xs">
                            <div class="col-xs-12 col-sm-6 col-p-5">
                                <div class="care-line-card">
                                    <span class="fa-stack fa-lg yellow">
                                        <i class="fa fa-circle fa-stack-2x"></i>
                                        <i class="fa fa-phone fa-stack-1x fa-inverse"></i>
                                    </span>
                                    <p>Our Care Line



</p>
                                    <a id="p_lt_ctl100_linkDidIWinOurCareLineLink_btnElem_hyperLink" class="tel" href="tel:60320708668"><span id="p_lt_ctl100_linkDidIWinOurCareLineLink_btnElem_lblText">603 2070 8668</span></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="modal-how-much-can-i-win" class="modal-how-much-can-i-win modal modal-prior fade" role="dialog">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            How much can I win?




                        </h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body padding-0">
                        <div class="game-calculator game-4d">
                            <div class="row-0">
                                <div class="col-xs-12 col-md-6 padding-0">
                                    <div class="game-calculator-input">
                                        <div class="calculator-header">
                                            <h1>How Much Can I Win?



</h1>
                                        </div>
                                        <div class="calculator-content">
                                            <p>Get a quick estimate of your investments and winnings in any 4D Game!



</p>
                                            <div class="calculator-form-wrapper">
                                                <div class="form-group form-inline">
                                                    <label for="game" class="label">
                                                        Game




                                                    </label>
                                                    <div class="select-control">
                                                        <select id="game" class="form-control">
                                                            <option value="game-4d">4D / 万能万字 </option>
                                                            <option value="game-jackpot">4D Jackpot / 万字积宝</option>
                                                            <option value="game-powerball">4D Powerball / 万字双宝</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- Start - Multi Calculator 4D Form -->
                                                <div class="game-type-input-wrapper active" data-type="game-4d">
                                                    <div class="form-group form-multicalculator form-inline">
                                                        <label for="gameNumber" class="label">
                                                            4D Number




                                                        </label>
                                                        <input type="text" class="form-control width-short" id="gameNumber" placeholder="1234"
                                                            value="1234" data-validation="required,number,length" data-validation-length="4" maxlength="4">
                                                    </div>
                                                    <div class="form-group form-multicalculator form-inline">
                                                        <label for="gameType" class="label">
                                                            Play Type




                                                        </label>
                                                        <div class="select-control">
                                                            <select id="gameType" class="form-control"
                                                                data-validation="required">
                                                                <option value="Straight">Straight / 单一号码</option>
                                                                <option value="Permutate" >Permutate / 包字</option>
                                                                <option value="Roll">Roll / 打字</option>
                                                                <option value="mBox">mBox / 万字万保</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group form-multicalculator form-inline">
                                                        <label for="gameBetBig" class="label">
                                                            Big (RM)




                                                        </label>
                                                        <input type="text" class="form-control width-short" id="gameBetBig" placeholder="1"
                                                            value="1" data-validation="required,number" data-validation-allowing="range[1;999]" maxlength="3">
                                                    </div>
                                                    <div class="form-group form-multicalculator form-inline">
                                                        <label for="gameBetSmall" class="label">
                                                            Small (RM)




                                                        </label>
                                                        <input type="text" class="form-control width-short" id="gameBetSmall" placeholder="1"
                                                            value="1" data-validation="required,number" data-validation-allowing="range[1;999]" maxlength="3">
                                                    </div>
                                                </div>
                                                <!-- End - Multi Calculator 4D Form -->
                                                <!-- Start - Multi Calculator 4D Jackpot Form -->
                                                <div class="game-type-input-wrapper" data-type="game-jackpot">
                                                    <div class="form-group form-multicalculator form-inline">
                                                        <label for="gameFirstNumber" class="label">
                                                            1st 4D Number




                                                        </label>
                                                        <input type="text" class="form-control width-short" id="gameFirstNumber" placeholder="1234"
                                                            value="1234" data-validation="required,number,length" data-validation-length="4" maxlength="4">
                                                    </div>
                                                    <div class="form-group form-multicalculator form-inline space-bottom">
                                                        <label for="gameFirstType" class="label">
                                                            Play Type




                                                        </label>
                                                        <div class="select-control">
                                                            <select id="gameFirstType" class="form-control">
                                                                <option value="Straight" selected="">Straight / 单一号码</option>
                                                                <option value="Permutate">Permutate / 包字</option>
                                                                <option value="Roll">Roll / 打字</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group form-multicalculator form-inline">
                                                        <label for="gameSecondNumber" class="label">
                                                            2nd 4D Number




                                                        </label>
                                                        <input type="text" class="form-control width-short" id="gameSecondNumber" placeholder="5678"
                                                            value="5678" data-validation="required,number,length" data-validation-length="4" maxlength="4">
                                                    </div>
                                                    <div class="form-group form-multicalculator form-inline">
                                                        <label for="gameSecondType" class="label">
                                                            Play Type




                                                        </label>
                                                        <div class="select-control">
                                                            <select id="gameSecondType" class="form-control">
                                                                <option value="Straight">Straight / 单一号码</option>
                                                                <option value="Permutate" selected="">Permutate / 包字</option>
                                                                <option value="Roll">Roll / 打字</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End - Multi Calculator 4D Jackpot Form -->
                                                <!-- Start - Multi Calculator 4D Powerball Form -->
                                                <div class="game-type-input-wrapper" data-type="game-powerball">
                                                    <div class="form-group form-multicalculator form-inline">
                                                        <label for="combo4Digits" class="label">
                                                            4 Digits Combo




                                                        </label>
                                                        <div class="select-control">
                                                            <select id="combo4Digits" class="form-control">
                                                                <option value="1" selected>1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>                                                            
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group form-multicalculator form-inline">
                                                        <label for="comboPowerball" class="label">
                                                            POWERBALL Combo




                                                        </label>
                                                        <div class="select-control">
                                                            <select id="comboPowerball" class="form-control">
                                                                <option value="2" selected>2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End - Multi Calculator 4D Powerball Form -->
                                                <div class="form-group form-multicalculator form-inline form-submit">
                                                    <label class="label hidden-xs hidden-sm">&nbsp;</label>
                                                    <button id="btnCalculatorSubmit" type="submit" class="btn btn-primary btn-submit">
                                                        Submit




                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6 padding-0">
                                    <!-- Start - Multi Calculator 4D Result -->
                                    <div class="game-type-input-wrapper height-full active" data-type="game-4d">
                                        <div class="game-calculator-result">
                                            <div class="game-result-content">
                                                <div class="title">
                                                    Results




                                                </div>
                                                <div class="content">
                                                    <div class="total-numbers">
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-9 col-md-8 col-lg-9">
                                                                <div class="field-label">
                                                                    Total No. of 4D Numbers




                                                                </div>
                                                            </div>
                                                            <div class="col-xs-12 col-sm-3 col-md-4 col-lg-3">
                                                                <div id="result-4d-number-count" class="field-output">1</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="total-cost">
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-9 col-md-8 col-lg-9">
                                                                <div class="field-label">
                                                                    Total Cost




                                                                </div>
                                                            </div>
                                                            <div class="col-xs-12 col-sm-3 col-md-4 col-lg-3">
                                                                <div class="field-output">RM<span id="result-4d-total-cost">2.00</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="game-prize-content">
                                                <div class="title">
                                                    How much you can win.




                                                </div>
                                                <div class="content">
                                                    <p>These are your potential winnings based on your play amount and the prize that your number wins.



</p>
                                                    <div class="prize-lists">
                                                        <div class="prize-item">
                                                            <div class="row">
                                                                <div class="col-xs-12 col-md-3">
                                                                    <div class="prize-type">
                                                                        1st Prize




                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12 col-md-5">
                                                                    <div  class="prize-amount-total">RM<span id="result-total-first-prize">6,000</span></div>
                                                                </div>
                                                                <div class="col-xs-12 col-md-4">
                                                                    <div class="prize-amount-detail">
                                                                        <div  class="prize-big">Big



 RM<span id="result-big-first-prize">2,500</span></div>
                                                                        <div class="prize-small">Small



 RM<span id="result-small-first-prize">3,500</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="prize-item">
                                                            <div class="row">
                                                                <div class="col-xs-12 col-md-3">
                                                                    <div class="prize-type">
                                                                        2nd Prize




                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12 col-md-5">
                                                                    <div  class="prize-amount-total">RM<span id="result-total-second-prize">3,000</span></div>
                                                                </div>
                                                                <div class="col-xs-12 col-md-4">
                                                                    <div class="prize-amount-detail">
                                                                        <div class="prize-big">Big



 RM<span id="result-big-second-prize">1,000</span></div>
                                                                        <div class="prize-small">Small



 RM<span id="result-small-second-prize">2,000</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="prize-item">
                                                            <div class="row">
                                                                <div class="col-xs-12 col-md-3">
                                                                    <div class="prize-type">
                                                                        3rd Prize




                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12 col-md-5">
                                                                    <div class="prize-amount-total">RM<span id="result-total-third-prize">1,500</span></div>
                                                                </div>
                                                                <div class="col-xs-12 col-md-4">
                                                                    <div class="prize-amount-detail">
                                                                        <div class="prize-big">Big



 RM<span id="result-big-third-prize">500</span></div>
                                                                        <div class="prize-small">Small



 RM<span id="result-small-third-prize">1,000</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="prize-item">
                                                            <div class="row">
                                                                <div class="col-xs-12 col-md-3">
                                                                    <div class="prize-type">
                                                                        Special Prize




                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12 col-md-9">
                                                                    <div class="prize-amount-total small">RM<span id="result-special-prize">180</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="prize-item">
                                                            <div class="row">
                                                                <div class="col-xs-12 col-md-3">
                                                                    <div class="prize-type">
                                                                        Consolation Prize




                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12 col-md-9">
                                                                    <div class="prize-amount-total small">RM<span id="result-consolation-prize">60</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="features-message">
                                                    <p>Try <a href="#" target="_blank">mBox</a>, <a href="#" target="_blank">Permutate</a> or <a href="#" target="_blank">Roll Play</a> for a higher chance of winning.



</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End - Multi Calculator 4D Result -->
                                    <!-- Start - Multi Calculator 4D Jackpot Result -->
                                    <div class="game-type-input-wrapper height-full" data-type="game-jackpot">
                                        <div class="game-calculator-result">
                                            <div class="game-result-content">
                                                <div class="title">
                                                    Results




                                                </div>
                                                <div class="content">
                                                    <div class="first-number">
                                                        <div class="row">
                                                            <div class="col-xs-5">
                                                                <div class="field-label">
                                                                    1st Number




                                                                </div>
                                                            </div>
                                                            <div class="col-xs-7">
                                                                <div class="field-output"><span id = "result-firstNumber">1234</span> <small>(<span id="result-firstType">Straight</span>)</small> yields



 <span class="primary" id="result-firstPairCount">1</span> number



</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="second-number">
                                                        <div class="row">
                                                            <div class="col-xs-5">
                                                                <div class="field-label">
                                                                    2nd Number




                                                                </div>
                                                            </div>
                                                            <div class="col-xs-7">
                                                                <div class="field-output"><span id="result-secondNumber">5678</span> <small>(<span id="result-secondType">Permutate</span>)</small> yields



 <span class="primary" id="result-secondPairCount">24</span> number(s)



</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="total-numbers">
                                                        <div class="row">
                                                            <div class="col-xs-5">
                                                                <div class="field-label">
                                                                    Total No. of Pairs




                                                                </div>
                                                            </div>
                                                            <div class="col-xs-7">
                                                              <div class="field-output"><span id="result-jackpot-total-pair">24</span> 
                                                                  <span class="fa fa-question-circle cms-en hide" aria-hidden="true" data-toggle="tooltip" data-html="true" title="
                                                                      <div style='text-align:left;'>
                                                                      <p><strong>4D Jackpot</strong></p>
                                                                      <p>Total numbers of pairs(n) X RM2(each pair)= Total cost</p>
                                                                      <p><strong>4D Jackpot System Play</strong></p>
                                                                      <p>Total numbers of pairs(n) X RM2(each pair)= Total cost</p>
                                                                      <p><strong>4D Jackpot M-System</strong></p>
                                                                      <p>For only RM10 , you’ll get 10 sets of numbers<br>
                                                                      Add RM1 only for 1 additional set of numbers.<br>
                                                                      RM11-> 11 sets of numbers <br>
                                                                      RM15-> 15 sets of numbers <br>
                                                                      Up to RM50-> 50 sets of numbers</p>
                                                                  </div>"></span>
                                                                  <span class="fa fa-question-circle cms-cn hide" aria-hidden="true" data-toggle="tooltip" data-html="true" title="
                                                                      <div style='text-align:left;'>
                                                                      <p><strong>万字积宝</strong></p>
                                                                      <p>总投注数量（对）x RM2（每对）= 总投注额</p>
                                                                      <p><strong>万字积宝系统游戏</strong></p>
                                                                      <p>总投注数量（对）x RM2（每对）= 总投注额</p>
                                                                      <p><strong>万字积宝M-System</strong></p>
                                                                      <p>以最低投注额RM10，您将可选择10组万字号码。只需RM1即可额外投注其他号码。</p>
                                                                  </div>"></span>
                                                              </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="total-cost">
                                                        <div class="row">
                                                            <div class="col-xs-5">
                                                                <div class="field-label">
                                                                    Total Cost




                                                                </div>
                                                            </div>
                                                            <div class="col-xs-7">
                                                                <div class="field-output">RM<span id="result-jackpot-cost">48.00</span> 
                                                                   <span class="fa fa-question-circle cms-en hide" aria-hidden="true" data-toggle="tooltip" data-html="true" title="
                                                                      <div style='text-align:left;'>
                                                                      <p><strong>4D Jackpot</strong></p>
                                                                      <p>Total numbers of pairs(n) X RM2(each pair)= Total cost</p>
                                                                      <p><strong>4D Jackpot System Play</strong></p>
                                                                      <p>Total numbers of pairs(n) X RM2(each pair)= Total cost</p>
                                                                      <p><strong>4D Jackpot M-System</strong></p>
                                                                      <p>For only RM10 , you’ll get 10 sets of numbers<br>
                                                                      Add RM1 only for 1 additional set of numbers.<br>
                                                                      RM11-> 11 sets of numbers <br>
                                                                      RM15-> 15 sets of numbers <br>
                                                                      Up to RM50-> 50 sets of numbers</p>
                                                                  </div>"></span>
                                                                  <span class="fa fa-question-circle cms-cn hide" aria-hidden="true" data-toggle="tooltip" data-html="true" title="
                                                                      <div style='text-align:left;'>
                                                                      <p><strong>万字积宝</strong></p>
                                                                      <p>总投注数量（对）x RM2（每对）= 总投注额</p>
                                                                      <p><strong>万字积宝系统游戏</strong></p>
                                                                      <p>总投注数量（对）x RM2（每对）= 总投注额</p>
                                                                      <p><strong>万字积宝M-System</strong></p>
                                                                      <p>以最低投注额RM10，您将可选择10组万字号码。只需RM1即可额外投注其他号码。</p>
                                                                  </div>"></span>
    
                                                                 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="featured-view-option">
                                                        <h4>Also view our



</h4>
                                                        <a href="#" data-toggle="modal">
                                                            System Play Price Table




                                                        </a>
                                                        <span class="separator">/</span>
                                                        <a href="#" data-toggle="modal">
                                                            M-System Play Price Table




                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="game-prize-content">
                                                <div class="title">
                                                    Estimated Jackpot Prize at next draw




                                                </div>
                                                <div class="content">
                                                    <p>Here&#39;s what you&#39;ll win if the numbers you puchased win a Jackpot prize:



</p>
                                                    <div class="prize-lists">
                                                        <div class="prize-item">
                                                            <div class="row">
                                                                <div class="col-xs-12 col-md-3">
                                                                    <div class="prize-type">
                                                                        Jackpot 1




                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12 col-md-9">
                                                                    <div class="prize-amount-total jackpot1-4D-estimated-prize"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="prize-item">
                                                            <div class="row">
                                                                <div class="col-xs-12 col-md-3">
                                                                    <div class="prize-type">
                                                                        Jackpot 2




                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12 col-md-9">
                                                                    <div class="prize-amount-total jackpot2-4D-estimated-prize"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End - Multi Calculator 4D Jackpot Result -->
                                    <!-- Start - Multi Calculator 4D Powerball Result -->
                                    <div class="game-type-input-wrapper height-full" data-type="game-powerball">
                                        <div class="game-calculator-result">
                                            <div class="game-result-content">
                                                <div class="title">
                                                    Results




                                                </div>
                                                <div class="content">
                                                    <div class="total-numbers">
                                                        <div class="row">
                                                            <div class="col-xs-6">
                                                                <div class="field-label">
                                                                    Total No. of 4D POWERBALL numbers




                                                                </div>
                                                            </div>
                                                            <div class="col-xs-6">
                                                                <div class="field-output">Combo Bet&nbsp;



 <span id="result-combo4Digits">1</span> + <span id="result-comboPowerball">2</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="total-cost">
                                                        <div class="row">
                                                            <div class="col-xs-6">
                                                                <div class="field-label">
                                                                    Total Cost




                                                                </div>
                                                            </div>
                                                            <div class="col-xs-6">
                                                                <div class="field-output">RM<span id="result-powerball-cost">2.00</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="featured-view-option">
                                                        <h4>Also view our



</h4>
                                                        <a href="#" data-toggle="modal">
                                                            Combo Play Price Table




                                                        </a>
                                                        <span class="separator">/</span>
                                                        <a href="#" data-toggle="modal">
                                                            Permutate Play Price Table




                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="game-prize-content">
                                                <div class="title">
                                                    Estimated POWERBALL Prize at next draw




                                                </div>
                                                <div class="content">
                                                    <p>Here&#39;s what you&#39;ll win if the numbers you puchased wins a Jackpot prize:



</p>
                                                    <div class="prize-lists">
                                                        <div class="prize-item">
                                                            <div class="row">
                                                                <div class="col-xs-12 col-md-3">
                                                                    <div class="prize-type">
                                                                        Jackpot 1




                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12 col-md-9">
                                                                    <div class="prize-amount-total powerball1-4D-estimated-prize"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="prize-item">
                                                            <div class="row">
                                                                <div class="col-xs-12 col-md-3">
                                                                    <div class="prize-type">
                                                                        Jackpot 2




                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-12 col-md-9">
                                                                    <div class="prize-amount-total  powerball2-4D-estimated-prize"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End - Multi Calculator 4D Powerball Result -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="modal-number-details" class="modal-number-details modal modal-prior fade" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">NUMBER DETAILS



</h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="number-details-header row-0 middle-xs margin-side-0 margin-bottom-20">
                            <div class="col-xs-6 col-p-5">
                                <div class="number-details-image">
                                    <img id='number-details-img' class="img-responsive margin-left-auto">
                                </div>
                            </div>
                            <div class="col-xs-6 col-p-5 text-left">
                                <h3 id='number-details-title' class="number-details-title">2340



</h3>
                                <p id='number-details-name-en' class="number-details-name">Beef Steak



</p>
                                <p id = 'number-details-name-cn' class="number-details-name">牛排



</p>
                            </div>
                        </div>
                        <div class="number-calculator">
                            <h4 class="modal-body-title margin-bottom-0">Calculator



</h4>
                            <p class="modal-body-sub-title">Tally up how much you&#39;ve won!



</p>
                            <hr class="modal-body-divider">
    
                            <div id="number-calculator-form" class="number-calculator-form">
                                <input type='hidden' id='hdnNumberDetailPrizeType' value=''/>
                                <div class="form-row clearfix row-0 middle-xs">
                                    <div class="col-xs-12 col-sm-3 col-p-0">
                                        <label for="bet-type" class="label">Bet Type



</label>
                                    </div>
                                    <div class="form-group col-xs-12 col-sm-9 col-p-0">
                                        <select name="bet-type" id="bet-type" class="form-control">
                                            <option value="straight" selected>Straight / 单一号码</option>
                                            <option value="mBox">mBox / 万字万保</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-row clearfix row-0 middle-xs">
                                    <div class="col-xs-12 col-sm-3 col-p-0">
                                        <label for="bet-big" class="label">Big



</label>
                                    </div>
                                    <div class="col-xs-12 col-sm-9 col-p-0 clearfix" >
                                        <div class="form-group col-xs-12 col-sm-5 col-p-0 clearfix">
                                            <input type="number" id="bet-big" class="form-control"
                                               data-validation="required,number" data-validation-allowing="range[0;999]" maxlength="3">
                                        </div>
                                        <div class="col-xs-12 col-sm-7 col-p-0 clearfix row-0 middle-xs">
                                            <div class="col-sm-4 col-p-0">
                                                <label for="bet-small" class="label">Small



</label>
                                            </div>
                                            <div class="form-group col-xs-12 col-sm-8 col-p-0">
                                                <input type="number" id="bet-small" class="form-control"
                                                   data-validation="required,number" data-validation-allowing="range[0;999]" maxlength="3">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row text-right">
                                    <button id='btnNumberDetailsCalculate' class="btn btn-primary" type="submit">Submit



</button>
                                </div>
                            </div>
                            <div id = 'number-calculator-result' class="number-calculator-result">
                                <h3 class="title">Congratulations! You have won:



</h3>
                                <div class="number-result">
                                    <div class="row bottom-xs">
                                        <div class="col-xs-12 col-md-offset-1 col-md-10">
                                            <div class="row row-0 bottom-xs">
                                                <div class="col-xs-12 col-sm-7">
                                                    <div class="number-result-wrapper">
                                                        <span id='number-details-prize-label' class="prize-label block"></span>
                                                        <h2 class="prize yellow">RM<span id='number-details-prize-value' class="value"></span></h2>
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-5">
                                                    <p id='number-details-big-prize-value'></p>
                                                    <p id='number-details-small-prize-value'></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="download-app-card">
                            <p>Download our Magnum 4D app to check your results instantly.



</p>
                            <div class="clearfix">
                                <a style="width: 37%" class="col-xs-6 col-p-5" href="#">
                                    <img class="img-responsive margin-left-auto" src="https://www.magnum4d.my/Magnum4d/media/images/google-play.png" alt="Google Play">
                                </a>
                                <a style="width: 27%" class="col-xs-6 col-p-5" href="#">
                                    <img class="img-responsive margin-right-auto" src="https://www.magnum4d.my/Magnum4d/media/images/app-store.png" alt="App Store">
                                </a>
                                <a style="width: 33%" class="col-xs-6 col-p-5" target="_blank" href="#">
                                    <img class="img-responsive margin-right-auto" src="https://www.magnum4d.my/Magnum4d/media/images/huawei-gallery.png" alt="Huawei Gallery">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="modal-number-details-jp" class="modal-number-details-jp modal modal-prior fade" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">NUMBER DETAILS



</h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="number-details-header row-0 middle-xs margin-side-0 margin-bottom-20">
                            <div class="col-xs-6 col-p-5">
                                <div class="number-details-image">
                                    <img id='number-details-img-jp' class="img-responsive margin-left-auto">
                                </div>
                            </div>
                            <div class="col-xs-6 col-p-5 text-left">
                                <h3 id='number-details-title' class="number-details-title">2340



</h3>
                                <p id='number-details-name-en-jp' class="number-details-name">Beef Steak



</p>
                                <p id = 'number-details-name-cn-jp' class="number-details-name">牛排



</p>
                            </div>
                        </div>
                        <div class="download-app-card">
                            <p>Download our Magnum 4D app to check your results instantly.



</p>
                            <div class="clearfix">
                                <a style="width: 37%" class="col-xs-6 col-p-5" href="#">
                                    <img class="img-responsive margin-left-auto" src="https://www.magnum4d.my/Magnum4d/media/images/google-play.png" alt="Google Play">
                                </a>
                                <a style="width: 27%" class="col-xs-6 col-p-5" href="#">
                                    <img class="img-responsive margin-right-auto" src="https://www.magnum4d.my/Magnum4d/media/images/app-store.png" alt="App Store">
                                </a>
                                <a style="width: 33%" class="col-xs-6 col-p-5" target="_blank" href="#">
                                    <img class="img-responsive margin-right-auto" src="https://www.magnum4d.my/Magnum4d/media/images/huawei-gallery.png" alt="Huawei Gallery">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="modal-where-to-claim" class="modal-where-to-claim modal modal-prior fade" role="dialog">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            <p>Where To Claim?</p>





                        </h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table">
                            <tr>
                                <th>Prize Amount



</th>
                                <th>Claim Period



</th>
                                <th>Claim From



</th>
                                <th>Locate Us



</th>
                            </tr>
                            <tr>
                                <td>
                                    <span>Below



</span>
                                    <h4>RM50,000



</h4>
                                </td>
                                <td>6 months less one day



</td>
                                <td>Any Magnum 4D Outlets



</td>
                                <td>
                                    <a class="primary" href="#">Locate an Outlet



</a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Up To



</span>
                                    <h4>RM2,000,000



</h4>
                                </td>
                                <td>6 months less one day



</td>
                                <td><table>
	<tbody>
		<tr>
			<td>Any Magnum 4D State Office</td>
		</tr>
	</tbody>
</table>




</td>
                                <td>
                                    <a class="primary" href="#">Locate a State Office



</a>
                                </td>
                            </tr>
    
                            <tr>
                                <td>
                                    <span>Above



</span>
                                    <h4>RM2,000,000



</h4>
                                </td>
                                <td>6 months less one day



</td>
                                <td>Call 603 9212 2800 for an appoinment



</td>
                                <td>
                                    <a class="primary" href="#">Locate Our HQ



</a>
                                </td>
                            </tr>
    
                            <tr>
                                <td>
                                    <span><strong>Magnum Life Grand Prize</strong>



</span>
                                </td>
                                <td>6 months less one day



</td>
                                <td>Call 603 9212 2800 for an appointment



</td>
                                <td>
                                    <a class="primary" href="#">Locate Our HQ



</a>
                                </td>
                            </tr>
    
                            <tr>
                                <td>
                                    <span><strong>Magnum Life 2nd Prize</strong>



</span>
                                </td>
                                <td>6 months less one day



</td>
                                <td>Any Magnum 4D State Office



</td>
                                <td>
                                    <a class="primary" href="#">Locate a State Office



</a>
                                </td>
                            </tr>
    
                            <tr>
                                <td>
                                    <span><strong>Other Magnum Life Grand Prizes</strong>



</span>
                                </td>
                                <td>6 months less one day



</td>
                                <td>Any Magnum 4D Outlets



</td>
                                <td>
                                    <a class="primary" href="#">Locate an Outlet



</a>
                                </td>
                            </tr>
                        </table>
    
                        <div class="disclamer">
                            <p>* Only applicable to Magnum 4D Jackpot prizes.<br />
** There are no limits for prizes claim at any of the 4D Regional office in respect of the 4D Classic tickets.



</p>
                        </div>
    
                        <div class="row-5 text-center margin-top-10">
                            <div class="col-sm-6 col-p-5">
                                <div class="download-app-card">
                                    <p>Download our Magnum 4D app to check your results instantly.



</p>
                                    <div class="clearfix">
                                        <a style="width: 33.3%" class="col-xs-6 col-p-5" target="_blank" href="#">
                                            <img class="img-responsive margin-left-auto"
                                                 src="https://www.magnum4d.my/Magnum4d/media/images/google-play.png" alt="Google Play">
                                        </a>
                                        <a style="width: 33.3%" class="col-xs-6 col-p-5" target="_blank" href="#">
                                            <img class="img-responsive margin-right-auto"
                                                 src="https://www.magnum4d.my/Magnum4d/media/images/app-store.png" alt="App Store">
                                        </a>
                                        <a style="width: 33.3%" class="col-xs-6 col-p-5" target="_blank" href="#">
                                            <img class="img-responsive margin-right-auto"
                                                 src="https://www.magnum4d.my/Magnum4d/media/images/huawei-gallery.png" alt="Huawei Gallery">
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <br class="visible-xs">
                            <div class="col-xs-12 col-sm-6 col-p-5">
                                <div class="care-line-card">
                                        <span class="fa-stack fa-lg yellow">
                                          <i class="fa fa-circle fa-stack-2x"></i>
                                          <i class="fa fa-phone fa-stack-1x fa-inverse"></i>
                                        </span>
                                    <p>Our Care Line



</p>
                                    <a id="p_lt_ctl212_linkModalLabelOurCareLineLink_btnElem_hyperLink" class="tel" href="tel:+60392122800"><span id="p_lt_ctl212_linkModalLabelOurCareLineLink_btnElem_lblText">603 9212 2800</span></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="modal-generic-message" class="modal-generic-message modal modal-prior fade" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id = 'modal-generic-message-title'>Title</h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="check-your-number margin-top-15">
                            <h4 class="modal-body-title" id = 'modal-generic-message-content'>Content</h4>
                            <hr class="modal-body-divider">
                            <div id="check-number-form" class="check-number-form">
                                <div class="form-group text-right">
                                    <button class="btn btn-primary" type="submit" data-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="modal-how-to-draw" class="modal-how-to-draw modal modal-prior fade" role="dialog">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            How are draws conducted?




                        </h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal-body-description text-center">
                            <h1 class="description-title">
                                Random and Fair




                            </h1>
                            <div class="description-text">
                                <p>Every step of our prize draw process has been designed make it as random and fair as possible.</p>

<p>This becomes evident when you look at what happens during a typical prize draw.</p>





                            </div>
                        </div>
                        <div class="modal-body-content">
                            <div class="how-to-draw-step step-1 bg-white">
                                <div class="bg-small-color grey"></div>
                                <div class="how-to-draw-step-content">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-2">
                                            <div class="step-title">
                                                <div class="step-title-wrapper">
                                                    <h3>Step



</h3>
                                                    <h1>01</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-5">
                                            <div class="step-image">
                                                <img id="p_lt_ctl217_imgHowToDrawStep1Image_ucEditableImage_imgImage" src="https://www.magnum4d.my/Magnum4d/media/images/how-to-draw/member-audience.png" alt="" />


                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-5">
                                            <div class="step-content">
                                                All draws are conducted at our draw auditorium which is always open to members of the public. They are conducted by Magnum officers together with members of the public who are randomly selected just before the start of each draw.




                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="how-to-draw-step step-2 bg-yellow">
                                <div class="bg-small-color bg-left grey"></div>
                                <div class="how-to-draw-step-content">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-2">
                                            <div class="step-title">
                                                <div class="step-title-wrapper">
                                                    <h3>Step



</h3>
                                                    <h1>02</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-5">
                                            <div class="step-image">
                                                <img id="p_lt_ctl220_imgHowToDrawStep2Image_ucEditableImage_imgImage" src="https://www.magnum4d.my/Magnum4d/media/images/how-to-draw/drums-balls.png" alt="" />


                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-5">
                                            <div class="step-content">
                                                Each of the 23 winning numbers is randomly drawn using see-through electromechanical drums. These drums do not require direct human contact as they are electronically controlled and operated by electromechanical motors.




                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="how-to-draw-step step-3 bg-white">
                                <div class="bg-small-color grey"></div>
                                <div class="how-to-draw-step-content">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-2">
                                            <div class="step-title">
                                                <div class="step-title-wrapper">
                                                    <h3>Step



</h3>
                                                    <h1>03</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-5">
                                            <div class="step-image">
                                                <img id="p_lt_ctl223_imgHowToDrawStep3Image_ucEditableImage_imgImage" src="https://www.magnum4d.my/Magnum4d/media/images/how-to-draw/winning-numbers.png" alt="" />


                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-5">
                                            <div class="step-content">
                                                From the start to the finish, it is the selected members of the public that actually draw each and every one of the 23 winning numbers.&nbsp;




                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="how-to-draw-step step-4 bg-yellow">
                                <div class="bg-small-color bg-left grey"></div>
                                <div class="how-to-draw-step-content">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-2">
                                            <div class="step-title">
                                                <div class="step-title-wrapper">
                                                    <h3>Step



</h3>
                                                    <h1>04</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-5">
                                            <div class="step-image">
                                                <img id="p_lt_ctl226_imgHowToDrawStep4Image_ucEditableImage_imgImage" src="https://www.magnum4d.my/Magnum4d/media/images/how-to-draw/balls-settle.png" alt="" />


                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-5">
                                            <div class="step-content">
                                                By pressing a remote control button when instructed by the master of ceremony, they are the ones who activate the drums to allow one ball to be randomly captured by each drum, thereby drawing out the winning number.




                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="how-to-draw-step step-5 bg-white">
                                <div class="bg-small-color grey"></div>
                                <div class="how-to-draw-step-content">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-2">
                                            <div class="step-title">
                                                <div class="step-title-wrapper">
                                                    <h3>Step



</h3>
                                                    <h1>05</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-5">
                                            <div class="step-image">
                                                <img id="p_lt_ctl229_imgHowToDrawStep5Image_ucEditableImage_imgImage" src="https://www.magnum4d.my/Magnum4d/media/images/how-to-draw/prizes.png" alt="" />


                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-5">
                                            <div class="step-content">
                                                Please feel free to come and watch the draw proceedings at our Draw Auditorium at Magnum House, 111 Jalan Pudu, Kuala Lumpur.




                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="how-to-draw-step step-6 bg-yellow hidden">
                                <div class="bg-small-color bg-left grey"></div>
                                <div class="how-to-draw-step-content">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-2">
                                            <div class="step-title">
                                                <div class="step-title-wrapper">
                                                    <h3>Step



</h3>
                                                    <h1>06</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-5">
                                            <div class="step-image">
                                                <img id="p_lt_ctl232_imgHowToDrawStep6Image_ucEditableImage_imgImage" src="https://www.magnum4d.my/Magnum4d/media/images/how-to-draw/powerball.png" alt="" />


                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-5">
                                            <div class="step-content">
                                                <strong>Powerball </strong>numbers are drawn separately with the same draw equipment and numbered balls.




                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="how-to-draw-message">
                                <div class="row">
                                    <div class="col-xs-12 col-md-offset-1 col-md-10">
                                        <p>As you can see, the draw is conducted using purely mechanical means, with no computers involved.</p>

<p>Our players can have complete confidence that our winning numbers are determined completely by chance and the draw itself cannot be manipulated, hacked or tampered with in any way whatsoever.</p>





                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="modal-million-ringgit-contact" class="modal-million-ringgit-contact modal modal-prior fade" role="dialog">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            Relevant Contact




                        </h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table">
                            <tr>
                                <th>State



</th>
                                <th>Contact Number



</th>
                                <th>Contact Person



</th>
                            </tr>
                            <tr>
                                <td><strong>Kuala Lumpur/Selangor</strong>



</td>
                                <td><a href="tel:0321488366">03-21488366</a>



</td>
                                <td>Ms. Amy Loh<br />
Mr. Steven Fan



</td>
                            </tr>
                            <tr>
                                <td><strong>East Coast</strong>



</td>
                                <td><a href="tel:095142970">09-5142970</a>



</td>
                                <td>Mr. Tan Teck Soon<br />
Ms. Pushpa



</td>
                            </tr>
                            <tr>
                                <td><strong>Penang</strong>



</td>
                                <td><a href="tel:042815923">04-2815923</a>



</td>
                                <td>Mr. Chris Tan<br />
Mr. Wei Chen



</td>
                            </tr>
                            <tr>
                                <td><strong>Perak</strong>



</td>
                                <td><a href="tel:052416793">05-2416793</a>



</td>
                                <td>Mr. Chong Teck Boon<br />
Mr. Eddie Lee<br />
Ms. Tom Tuan



</td>
                            </tr>
                            <tr>
                                <td><strong>Melaka</strong>



</td>
                                <td><a href="tel:062845993">06-2845993</a>



</td>
                                <td>Ms. Fanny Han<br />
Mr. Jason Lye



</td>
                            </tr>
                            <tr>
                                <td><strong>Negeri Sembilan</strong>



</td>
                                <td><a href="tel:067621218">06-7621218</a>



</td>
                                <td>Mr. Gan Hock Sin<br />
Ms. Wong Yook Siew<br />
Ms. Loo Yee Mei



</td>
                            </tr>
                            <tr>
                                <td><strong>Johor</strong>



</td>
                                <td><a href="tel:075588317">07-5588317</a><br />
<a href="tel:075588910">07-5588910</a>



</td>
                                <td>Ms. Kit Chiew



</td>
                            </tr>
                            <tr>
                                <td><strong>East Malaysia</strong>



</td>
                                <td><a href="tel:082245034">082-245034</a>



</td>
                                <td>Mr. Tan Jee Shen<br />
Ms. Cecilia Chai<br />
Ms. Joenn Goh



</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="modal-jackpot-winning-numbers" class="modal-jackpot-winning-numbers modal modal-prior fade" role="dialog">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            All 4D Jackpot Winning Numbers




                        </h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="prize-winning-box jackpot-1">
                            <div class="prize-details">
                                <span class="prize-type">
                                    Jackpot




                                    <strong>1 Prize



</strong>
                                </span>
                                <span class="jackpot-1-prize-amount prize-amount">RM12,000,000.00</span>
                            </div>
                            <div class="winning-number-list">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-offset-3 col-sm-6 col-md-offset-2 col-md-8">
                                        <p>
                                            You would have won <strong>Jackpot 1</strong> prize if you had bought any of the following pairs.




                                        </p>
                                        <div class="row">
                                            <div class="col-xs-12 col-md-offset-3 col-md-6">
                                                <ul>
                                                    <li>
                                                        <span class="first-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="second-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="first-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="third-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="second-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="first-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="second-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="third-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="third-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="first-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="third-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="second-prize number-item">4624</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="prize-winning-box jackpot-2">
                            <div class="prize-details">
                                <span class="prize-type">
                                    Jackpot




                                    <strong>2 Prize



</strong>
                                </span>
                                <span class="jackpot-2-prize-amount prize-amount">RM200,000.00</span>
                            </div>
                            <div class="winning-number-list">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-offset-2 col-sm-8">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <p>
                                                    You would have won&nbsp;<strong>Jackpot 2</strong>&nbsp;prize if you had bought any of the following pairs.




                                                </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-4 col-p-5">
                                                <ul>
                                                    <li>
                                                        <span class="first-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-1 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="first-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-2 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="first-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-3 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="first-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-4 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="first-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-5 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="first-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-6 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="first-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-7 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="first-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-8 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="first-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-9 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="first-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-10 number-item">4624</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="col-xs-4 col-p-5">
                                                <ul>
                                                    <li>
                                                        <span class="second-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-1 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="second-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-2 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="second-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-3 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="second-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-4 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="second-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-5 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="second-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-6 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="second-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-7 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="second-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-8 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="second-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-9 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="second-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-10 number-item">4624</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="col-xs-4 col-p-5">
                                                <ul>
                                                    <li>
                                                        <span class="third-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-1 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="third-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-2 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="third-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-3 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="third-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-4 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="third-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-5 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="third-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-6 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="third-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-7 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="third-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-8 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="third-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-9 number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="third-prize number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="special-prize-10 number-item">4624</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="col-xs-4 col-p-5">
                                                <ul>
                                                    <li>
                                                        <span class="special-prize-1 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="first-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-2 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="first-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-3 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="first-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-4 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="first-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-5 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="first-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-6 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="first-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-7 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="first-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-8 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="first-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-9 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="first-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-10 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="first-prize number-item">4624</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="col-xs-4 col-p-5">
                                                <ul>
                                                    <li>
                                                        <span class="special-prize-1 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="second-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-2 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="second-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-3 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="second-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-4 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="second-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-5 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="second-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-6 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="second-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-7 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="second-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-8 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="second-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-9 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="second-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-10 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="second-prize number-item">4624</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="col-xs-4 col-p-5">
                                                <ul>
                                                    <li>
                                                        <span class="special-prize-1 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="third-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-2 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="third-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-3 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="third-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-4 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="third-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-5 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="third-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-6 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="third-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-7 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="third-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-8 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="third-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-9 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="third-prize number-item">4624</span>
                                                    </li>
                                                    <li>
                                                        <span class="special-prize-10 number-item">9109</span>
                                                        <span class="margin-sm-side-2 number-pairing">
                                                            <i class="fa fa-plus"></i>
                                                        </span>
                                                        <span class="third-prize number-item">4624</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="prize-winning-box jackpot-prize-3">
                            <div class="row">
                                <div class="col-xs-4 col-sm-3 col-md-2">
                                    <span class="prize-type">
                                        3rd PRIZE




                                    </span>
                                </div>
                                <div class="col-xs-8 col-sm-9 col-md-10 col-p-0">
                                    <p>
                                        You would have won Group 3 prize if&nbsp;<strong>&quot;Any one of the 4D numbers in your chosen pair matches either the 1st, 2nd or 3rd prize&quot;</strong>.




                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="prize-winning-box jackpot-prize-4">
                            <div class="row">
                                <div class="col-xs-4 col-sm-3 col-md-2">
                                    <span class="prize-type">
                                        4th PRIZE




                                    </span>
                                </div>
                                <div class="col-xs-8 col-sm-9 col-md-10 col-p-0">
                                    <p>
                                        You would have won Group 4 prize if&nbsp;<strong>&quot;Any one of the 4D numbers in your chosen pair matches any one of the ten (10) Special prizes&quot;</strong>.




                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="prize-winning-box jackpot-prize-5">
                            <div class="row">
                                <div class="col-xs-4 col-sm-3 col-md-2">
                                    <span class="prize-type">
                                        5th PRIZE




                                    </span>
                                </div>
                                <div class="col-xs-8 col-sm-9 col-md-10 col-p-0">
                                    <p>
                                        You would have won Group 5 prize if&nbsp;<strong>&quot;Any one of the 4D numbers in your chosen pair must match any one of the ten (10) Consolation prizes&quot;</strong>.




                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="modal-cta-wrapper">
                            <button class="btn btn-primary btn-print-modal waves-effect">
                                Print




                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div id="modal-system-play-price-table" class="modal-system-play-price-table modal fade" role="dialog"> 
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            SYSTEM PLAY PRICE TABLE




                        </h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive table-system-play-price">
                            <table class="table table-bordered table-text-center table-text-valign-middle">
                                <thead>
                                    <tr>
                                        <th>SYSTEM PLAY



</th>
                                        <th>NO. OF 4D NUMBERS



</th>
                                        <th>NO. OF PAIRS



</th>
                                        <th>TOTAL COST (AT RM2/PAIR)



</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>SB3



</td>
                                        <td>3



</td>
                                        <td>3



</td>
                                        <td>6



</td>
                                    </tr>
                                    <tr>
                                        <td>SB4



</td>
                                        <td>4



</td>
                                        <td>6



</td>
                                        <td>12



</td>
                                    </tr>
                                    <tr>
                                        <td>SB5



</td>
                                        <td>5



</td>
                                        <td>10



</td>
                                        <td>20



</td>
                                    </tr>
                                    <tr>
                                        <td>SB6



</td>
                                        <td>6



</td>
                                        <td>15



</td>
                                        <td>30



</td>
                                    </tr>
                                    <tr>
                                        <td>SB7



</td>
                                        <td>7



</td>
                                        <td>21



</td>
                                        <td>42



</td>
                                    </tr>
                                    <tr>
                                        <td>SB8



</td>
                                        <td>8



</td>
                                        <td>28



</td>
                                        <td>56



</td>
                                    </tr>
                                    <tr>
                                        <td>SB9



</td>
                                        <td>9



</td>
                                        <td>36



</td>
                                        <td>72



</td>
                                    </tr>
                                    <tr>
                                        <td>SB10



</td>
                                        <td>10



</td>
                                        <td>45



</td>
                                        <td>90



</td>
                                    </tr>
                                    <tr>
                                        <td>SB11



</td>
                                        <td>11



</td>
                                        <td>55



</td>
                                        <td>110



</td>
                                    </tr>
                                    <tr>
                                        <td>SB12



</td>
                                        <td>12



</td>
                                        <td>66



</td>
                                        <td>132



</td>
                                    </tr>
                                    <tr>
                                        <td>SB13



</td>
                                        <td>13



</td>
                                        <td>78



</td>
                                        <td>156



</td>
                                    </tr>
                                    <tr>
                                        <td>SB14



</td>
                                        <td>14



</td>
                                        <td>91



</td>
                                        <td>182



</td>
                                    </tr>
                                    <tr>
                                        <td>SB15



</td>
                                        <td>15



</td>
                                        <td>105



</td>
                                        <td>210



</td>
                                    </tr>
                                    <tr>
                                        <td>SB16



</td>
                                        <td>16



</td>
                                        <td>120



</td>
                                        <td>240



</td>
                                    </tr>
                                    <tr>
                                        <td>SB17



</td>
                                        <td>17



</td>
                                        <td>136



</td>
                                        <td>272



</td>
                                    </tr>
                                    <tr>
                                        <td>SB18



</td>
                                        <td>18



</td>
                                        <td>153



</td>
                                        <td>306



</td>
                                    </tr>
                                    <tr>
                                        <td>SB19



</td>
                                        <td>19



</td>
                                        <td>171



</td>
                                        <td>342



</td>
                                    </tr>
                                    <tr>
                                        <td>SB20



</td>
                                        <td>20



</td>
                                        <td>190



</td>
                                        <td>380



</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="modal-msystem-play-price-table" class="modal-msystem-play-price-table modal fade" role="dialog"> 
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            M-SYSTEM PLAY PRICE TABLE




                        </h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive table-system-play-price">
                            <table class="table table-bordered table-text-center table-text-valign-middle">
                                <thead>
                                    <tr>
                                        <th>M - SYSTEM



</th>
                                        <th>COST



</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>10



</td>
                                        <td>10



</td>
                                    </tr>
                                    <tr>
                                        <td>11



</td>
                                        <td>11



</td>
                                    </tr>
                                    <tr>
                                        <td>12



</td>
                                        <td>12



</td>
                                    </tr>
                                    <tr>
                                        <td>13



</td>
                                        <td>13



</td>
                                    </tr>
                                    <tr>
                                        <td>14



</td>
                                        <td>14



</td>
                                    </tr>
                                    <tr>
                                        <td>15



</td>
                                        <td>15



</td>
                                    </tr>
                                    <tr>
                                        <td>16



</td>
                                        <td>16



</td>
                                    </tr>
                                    <tr>
                                        <td>17



</td>
                                        <td>17



</td>
                                    </tr>
                                    <tr>
                                        <td>18



</td>
                                        <td>18



</td>
                                    </tr>
                                    <tr>
                                        <td>19



</td>
                                        <td>19



</td>
                                    </tr>
                                    <tr>
                                        <td>20



</td>
                                        <td>20



</td>
                                    </tr>
                                    <tr>
                                        <td>21



</td>
                                        <td>21



</td>
                                    </tr>
                                    <tr>
                                        <td>22



</td>
                                        <td>22



</td>
                                    </tr>
                                    <tr>
                                        <td>23



</td>
                                        <td>23



</td>
                                    </tr>
                                    <tr>
                                        <td>24



</td>
                                        <td>24



</td>
                                    </tr>
                                    <tr>
                                        <td>25



</td>
                                        <td>25



</td>
                                    </tr>
                                    <tr>
                                        <td>26



</td>
                                        <td>26



</td>
                                    </tr>
                                    <tr>
                                        <td>27



</td>
                                        <td>27



</td>
                                    </tr>
                                    <tr>
                                        <td>28



</td>
                                        <td>28



</td>
                                    </tr>
                                    <tr>
                                        <td>29



</td>
                                        <td>29



</td>
                                    </tr>
                                    <tr>
                                        <td>30



</td>
                                        <td>30



</td>
                                    </tr>
                                    <tr>
                                        <td>31



</td>
                                        <td>31



</td>
                                    </tr>
                                    <tr>
                                        <td>32



</td>
                                        <td>32



</td>
                                    </tr>
                                    <tr>
                                        <td>33



</td>
                                        <td>33



</td>
                                    </tr>
                                    <tr>
                                        <td>34



</td>
                                        <td>34



</td>
                                    </tr>
                                    <tr>
                                        <td>35



</td>
                                        <td>35



</td>
                                    </tr>
                                    <tr>
                                        <td>36



</td>
                                        <td>36



</td>
                                    </tr>
                                    <tr>
                                        <td>37



</td>
                                        <td>37



</td>
                                    </tr>
                                    <tr>
                                        <td>38



</td>
                                        <td>38



</td>
                                    </tr>
                                    <tr>
                                        <td>39



</td>
                                        <td>39



</td>
                                    </tr>
                                    <tr>
                                        <td>40



</td>
                                        <td>40



</td>
                                    </tr>
                                    <tr>
                                        <td>41



</td>
                                        <td>41



</td>
                                    </tr>
                                    <tr>
                                        <td>42



</td>
                                        <td>42



</td>
                                    </tr>
                                    <tr>
                                        <td>43



</td>
                                        <td>43



</td>
                                    </tr>
                                    <tr>
                                        <td>44



</td>
                                        <td>44



</td>
                                    </tr>
                                    <tr>
                                        <td>45



</td>
                                        <td>45



</td>
                                    </tr>
                                    <tr>
                                        <td>46



</td>
                                        <td>46



</td>
                                    </tr>
                                    <tr>
                                        <td>47



</td>
                                        <td>47



</td>
                                    </tr>
                                    <tr>
                                        <td>48



</td>
                                        <td>48



</td>
                                    </tr>
                                    <tr>
                                        <td>49



</td>
                                        <td>49



</td>
                                    </tr>
                                    <tr>
                                        <td>50



</td>
                                        <td>50



</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="modal-combo-play-price-table" class="modal-combo-play-price-table modal fade" role="dialog"> 
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            Combo Play Price Table




                        </h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>For ease of calculation, below is a matrix of how much it will cost for various combinations for your Combo Bet.</p>





                        <div class="table-responsive table-system-play-price">
                            <table class="table table-bordered table-text-center table-text-valign-middle">
                                <thead>
                                    <tr>
                                        <th>Number of Powerball numbers



</th>
                                        <th>2



</th>
                                        <th>3



</th>
                                        <th>4



</th>
                                        <th>5



</th>
                                        <th>6



</th>
                                        <th>7



</th>
                                        <th>8



</th>
                                        <th>9



</th>
                                        <th>10



</th>
                                    </tr>
                                    <tr>
                                        <th>Number of 4D numbers



</th>
                                        <th colspan="9">RM (Ringgit Malaysia)



</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1



</td>
                                        <td>-



</td>
                                        <td>6



</td>
                                        <td>12



</td>
                                        <td>20



</td>
                                        <td>30



</td>
                                        <td>42



</td>
                                        <td>56



</td>
                                        <td>72



</td>
                                        <td>90



</td>
                                    </tr>
                                    <tr>
                                        <td>2



</td>
                                        <td>4



</td>
                                        <td>*12



</td>
                                        <td>24



</td>
                                        <td>40



</td>
                                        <td>60



</td>
                                        <td>84



</td>
                                        <td>112



</td>
                                        <td>144



</td>
                                        <td>180



</td>
                                    </tr>
                                    <tr>
                                        <td>3



</td>
                                        <td>6



</td>
                                        <td>18



</td>
                                        <td>36



</td>
                                        <td>60



</td>
                                        <td>90



</td>
                                        <td>126



</td>
                                        <td>168



</td>
                                        <td>216



</td>
                                        <td>270



</td>
                                    </tr>
                                    <tr>
                                        <td>4



</td>
                                        <td>8



</td>
                                        <td>24



</td>
                                        <td>48



</td>
                                        <td>80



</td>
                                        <td>120



</td>
                                        <td>168



</td>
                                        <td>224



</td>
                                        <td>288



</td>
                                        <td>360



</td>
                                    </tr>
                                    <tr>
                                        <td>5



</td>
                                        <td>10



</td>
                                        <td>30



</td>
                                        <td>60



</td>
                                        <td>100



</td>
                                        <td>150



</td>
                                        <td>210



</td>
                                        <td>280



</td>
                                        <td>360



</td>
                                        <td>450



</td>
                                    </tr>
                                    <tr>
                                        <td>6



</td>
                                        <td>12



</td>
                                        <td>36



</td>
                                        <td>72



</td>
                                        <td>120



</td>
                                        <td>180



</td>
                                        <td>252



</td>
                                        <td>336



</td>
                                        <td>432



</td>
                                        <td>540



</td>
                                    </tr>
                                    <tr>
                                        <td>7



</td>
                                        <td>14



</td>
                                        <td>42



</td>
                                        <td>84



</td>
                                        <td>140



</td>
                                        <td>210



</td>
                                        <td>294



</td>
                                        <td>392



</td>
                                        <td>504



</td>
                                        <td>630



</td>
                                    </tr>
                                    <tr>
                                        <td>8



</td>
                                        <td>16



</td>
                                        <td>48



</td>
                                        <td>96



</td>
                                        <td>160



</td>
                                        <td>240



</td>
                                        <td>336



</td>
                                        <td>448



</td>
                                        <td>576



</td>
                                        <td>720



</td>
                                    </tr>
                                    <tr>
                                        <td>9



</td>
                                        <td>18



</td>
                                        <td>54



</td>
                                        <td>108



</td>
                                        <td>180



</td>
                                        <td>270



</td>
                                        <td>378



</td>
                                        <td>504



</td>
                                        <td>648



</td>
                                        <td>810



</td>
                                    </tr>
                                    <tr>
                                        <td>10



</td>
                                        <td>20



</td>
                                        <td>60



</td>
                                        <td>120



</td>
                                        <td>200



</td>
                                        <td>300



</td>
                                        <td>420



</td>
                                        <td>560



</td>
                                        <td>720



</td>
                                        <td>900



</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>*Popular choice<br />
&nbsp;



</p>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="modal-permutate-play-price-table" class="modal-msystem-play-price-table modal fade" role="dialog"> 
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            Permutate Play Price Table




                        </h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>When you play Permutation Bet, you cover all possible permutations of the 4D number you picked. For example, if you play Permutation Bet on 1133, you cover 1133, 1313, 1331, 3131, 3311 and 3113.</p>

<p>Note: <strong>Permutation Bet is only for the 4D number. There is no such play for the POWERBALL number</strong>.</p>

<p>The number of possible permutations for a given 4D number depends on the number of unique digits in the 4D number as shown in the table below.</p>





                        <div class="table-responsive table-system-play-price">
                            <table class="table table-bordered table-text-center table-text-valign-middle">
                                <thead>
                                    <tr>
                                        <th>Number of Unique Digits in the 4D Number



</th>
                                        <th>Number of Permutations



</th>
                                        <th>Indication



</th>
                                        <th>Minimum Investment



</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Where all 4 digits are unique, e.g. 1234



</td>
                                        <td>24



</td>
                                        <td>P24



</td>
                                        <td>RM48



</td>
                                    </tr>
                                    <tr>
                                        <td>Where 2 of the 4 digits are the same, e.g. 1123



</td>
                                        <td>12



</td>
                                        <td>P12



</td>
                                        <td>RM24



</td>
                                    </tr>
                                    <tr>
                                        <td>Where there are 2 unique pairs of digit, e.g. 2727



</td>
                                        <td>6



</td>
                                        <td>P6



</td>
                                        <td>RM12



</td>
                                    </tr>
                                    <tr>
                                        <td>Where 3 of the 4 digits are the same, e.g. 3331



</td>
                                        <td>4



</td>
                                        <td>P4



</td>
                                        <td>RM8



</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div id="modal-mgold-prize-winning-table" class="modal-mgold-prize-winning-table modal fade" role="dialog"> 
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            <span style="text-transform: lowercase;">m</span>G<span style="text-transform: lowercase;">old</span> Prize Winning Table




                        </h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        




                        <div class="table-responsive table-mgold-prize-winning">
                            <table class="table table-bordered table-text-center table-text-valign-middle">
                                <thead>
                                    <tr>
                                        <th>Investment Amount



</th>
                                        <th>Match 6 numbers*



</th>
                                        <th>Match 5 numbers**



</th>
                                        <th>Match 4 numbers



</th>
                                        <th>Match 3 numbers



</th>
                                        <th>Match 2 numbers



</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><strong>RM2</strong>



</td>
                                        <td>200,000



</td>
                                        <td>8,388



</td>
                                        <td>338



</td>
                                        <td>38



</td>
                                        <td>4



</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RM3</strong>



</td>
                                        <td>300,000



</td>
                                        <td>12,582



</td>
                                        <td>507



</td>
                                        <td>57



</td>
                                        <td>6



</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RM4</strong>



</td>
                                        <td>400,000



</td>
                                        <td>16,776



</td>
                                        <td>676



</td>
                                        <td>76



</td>
                                        <td>8



</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RM5</strong>



</td>
                                        <td>500,000



</td>
                                        <td>20,970



</td>
                                        <td>845



</td>
                                        <td>95



</td>
                                        <td>10



</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RM6</strong>



</td>
                                        <td>600,000



</td>
                                        <td>25,164



</td>
                                        <td>1,014



</td>
                                        <td>114



</td>
                                        <td>12



</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RM7</strong>



</td>
                                        <td>700,000



</td>
                                        <td>29,358



</td>
                                        <td>1,183



</td>
                                        <td>133



</td>
                                        <td>14



</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RM8</strong>



</td>
                                        <td>800,000



</td>
                                        <td>33,552



</td>
                                        <td>1,352



</td>
                                        <td>152



</td>
                                        <td>16



</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RM9</strong>



</td>
                                        <td>900,000



</td>
                                        <td>37,746



</td>
                                        <td>1,521



</td>
                                        <td>171



</td>
                                        <td>18



</td>
                                    </tr>
                                    <tr>
                                        <td><strong>RM10</strong>



</td>
                                        <td>1,000,000



</td>
                                        <td>41,940



</td>
                                        <td>1,690



</td>
                                        <td>190



</td>
                                        <td>20



</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <small>* minimum winnings based on minimum JP Gold 1 amount of RM2,000,000</small><br />
<small>** minimum winnings based on minimum JP Gold 2 amount of RM100,000</small>




                    </div>
                </div>
            </div>
        </div>
        
    
        <!--Modal to display the Dates of the upcoming Draw Dates-->
        <div id="modal-next-draw-dates" class="modal modal-prior fade" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 class="modal-title" id=''>
                            <span class="win-cup"></span>
                            Draw Dates




                        </h2>
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div id="next-6-draws-date-picker" class="next-draw-date-picker"></div>
                        <div class="modal-footer">
                            <span>You can purchase up to 6 draws in advance



</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/End Modal to display the Dates of the upcoming Draw Dates-->
    
    </main>
    
    <div id="global-templates">
        <table style="display:none" id="didIWinHeaderTemplate">
            <tr id="hr">
                <th class="text-uppercase">
                    <span class='cms-en hide'>Number</span><span class='cms-cn hide'>号码</span>
                </th>
                <th class="text-uppercase">
                    <span class='cms-en hide'>Prize Type</span><span class='cms-cn hide'>奖金类别</span>
                </th>
                <th class="text-uppercase">
                    <span class='cms-en hide'>Prize</span><span class='cms-cn hide'>奖金</span>
                </th>
            </tr>
        </table>
        <table style="display:none" id="didIWinResultTemplate">
            <tr>
                <td>
                    <b>{Number}</b>
                </td>
                <td>
                    <b>{PrizeType}</b>
                </td>
                <td>
                    <b>{Prize}</b>
                </td>
            </tr>
        </table>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import moment from 'moment-timezone'
import axios from 'axios'
export default {
  name: 'Home',
  mounted () {
    //this.getBackGround()
    this.getLotto()
    //this.getBanners()
  },
  data () {
    return {
      lottos:[],
    }
  },
  methods: {
    randomImage() {
      const images = [
        'https://www.magnum4d.my/Magnum4d/media/images/banner/cross-sell/4d-mgold-cross-sell.jpg?ext=.jpg',
        'https://www.magnum4d.my/Magnum4d/media/images/banner/cross-sell/Magnum-Life-cross-sell.jpg?ext=.jpg',
        'https://www.magnum4d.my/Magnum4d/media/images/banner/cross-sell/4d-jackpot-old-cross-sell.jpg?ext=.jpg',
        'https://www.magnum4d.my/Magnum4d/media/images/banner/cross-sell/4d-mgold-cross-sell.jpg?ext=.jpg',
        'https://www.magnum4d.my/Magnum4d/media/images/banner/cross-sell/Magnum-Life-cross-sell.jpg?ext=.jpg',
        'https://www.magnum4d.my/Magnum4d/media/images/banner/cross-sell/4d-jackpot-old-cross-sell.jpg?ext=.jpg'
      ];
      return images[Math.floor(Math.random() * images.length)];
    },
    addComma(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async getTimeServer() {
      try {
        const response = await axios.get('https://us-central1-lotto-landingpage2.cloudfunctions.net/gettimezone');
        return response.data.datetime;
      } catch (error){
        try {
          const response = await axios.get('https://worldtimeapi.org/api/timezone/Asia/Bangkok');
          return response.data.datetime;
        } catch (error) {
          console.error(error);
          throw error; // คุณสามารถเลือกจะรีเซ็ตและจัดการข้อผิดพลาดตามความต้องการ
        }
      }
    },
    async getLotto () {
      const timezone = await this.getTimeServer()
      let data = (await firebase.database().ref('/huay/41').orderByChild('datetime').limitToLast(5).once('value')).val()
      let data2 = (await firebase.database().ref('/huay/42').orderByChild('datetime').limitToLast(5).once('value')).val()
      data = _.orderBy(data, ['datetime'], ['desc'])
      data2 = _.orderBy(data2, ['datetime'], ['desc'])
      if (data) {
        const lottos = [];
        const data42 = [];
        for (const x in data2){
          const d = data2[x].datetime.split(' ')
          const date = d[0].split('-')
          const time = d[1].split(':')
          const datetime = Number(date[0] + '' + date[1] + '' + date[2] + '' + time[0] + '' + time[1])
          if (datetime < Number(moment(timezone).format('YYYYMMDDHHmm'))) {
            data42[date[0] + '' + date[1] + '' + date[2]] = data2[x]
          }
        }
        for (const x in data) {
          const d = data[x].datetime.split(' ')
          const date = d[0].split('-')
          const time = d[1].split(':')
          const datetime = Number(date[0] + '' + date[1] + '' + date[2] + '' + time[0] + '' + time[1])
          if (datetime <= Number(moment(timezone).format('YYYYMMDDHHmm'))) {
            data[x].showdate = moment(data[x].datetime).format('DD/MM/YYYY (ddd)')
            if(data42[date[0] + '' + date[1] + '' + date[2]]){
              data[x].first2 = data42[date[0] + '' + date[1] + '' + date[2]].first
              data[x].second2 = data42[date[0] + '' + date[1] + '' + date[2]].second
              data[x].jackpot3 = data42[date[0] + '' + date[1] + '' + date[2]].jackpot
              data[x].jackpot4 = data42[date[0] + '' + date[1] + '' + date[2]].jackpot2
            }else{
              data[x].first2 = '------'
              data[x].second2 = '--'
              data[x].jackpot3 = {
                1: '-----',
                2: '-----',
              }
              data[x].jackpot4 = {
                1: '-----',
                2: '-----',
              }
            }
            lottos.push(data[x])
          }else if(datetime < Number(moment(timezone).add(1,'hours').format('YYYYMMDDHHmm'))){
            
          }
          this.lottos = lottos
          //if (!this.first) { this.first = data[x] }
          //this.data.push(data[x])
        }
      }
      // data = (await firebase.database().ref('/huay/42').orderByChild('datetime').limitToLast(5).once('value')).val()
      // data = _.orderBy(data, ['datetime'], ['desc'])
      //setInterval(this.getLastData, 1000)
    },
    async getFullYear () {
      const timezone  = await this.getTimeServer()
      return moment(timezone).format('YYYY')
    },
    formatDate (date) {
      return moment(date).tz('Asia/Bangkok').format('DD/MM/YYYY')
    },
    formatTime (date) {
      if (date) { return moment(date).tz('Asia/Bangkok').format('HH:mm') }
    },
    dateToday () {
      return moment().tz('Asia/Bangkok').format('dddd, DD MMMM YYYY')
    },
    async getLastData () {
      const timezone = await this.getTimeServer()
      // console.log('this.selectdate',this.selectdate);
      // console.log("moment(timezone).format('YYYY-MM-DD')",moment(timezone).format('YYYY-MM-DD'));
      
      const datas = (await firebase.database().ref('/huay/4').orderByChild('datetime').startAt(moment(timezone).format('YYYY-MM-DD HH:mm')).limitToFirst(1).once('value')).val()
      if (datas) {
        for (const x in datas) {
          const data = datas[x]
          if (data && data.datetime === moment(timezone).format('YYYY-MM-DD HH:mm')) {
            if (this.first.five !== data.five && (this.selectdate === moment(timezone).format('YYYY-MM-DD') || !this.selectdate)) {
              this.first = data
              for (const x in this.loading) {
                this.loading[x] = false
              }
            }
          } else if ((this.selectdate === moment(timezone).format('YYYY-MM-DD') || !this.selectdate)) {
            const t1 = moment(timezone)
            const t2 = moment.tz(data.datetime+':00','Asia/Bangkok')
            const diff = t2.diff(t1, 'seconds')
            // $('#clock-lottery').FlipClock(diff, {
            //   countdown: true
            // });
            // console.log(diff);
            this.nextday = data
            this.renderCountDown(data.datetime)
            if (diff <= 2700) {
              for (const x in this.loading) {
                this.loading[x] = true
              }
              this.first.datetime = data.datetime
            }
            if (diff <= 900) {
              //checkbug
              //this.first = data
              // แถวสอง
              // 5 ตัว
              for (let i = 0; i < 8; i++) {
                const start = 900 - (i * 30)
                const end = 860 - (i * 30)
                this.checkAniDice(data, diff, start, end, 11 + i, 2 + i, 2 + i, 5)
              }
              // 4 ตัว
              for (let i = 0; i < 10; i++) {
                const start = 650 - (i * 30)
                const end = 620 - (i * 30)
                this.checkAniDice(data, diff, start, end, 19 + i, 10 + i, 10 + i, 4)
              }
              // 3 ตัว
              for (let i = 0; i < 3; i++) {
                const start = 350 - (i * 30)
                const end = 320 - (i * 30)
                this.checkAniDice(data, diff, start, end, 29 + i, 20 + i, 20 + i, 3)
              }
              // 2 ตัว
              for (let i = 0; i < 4; i++) {
                const start = 260 - (i * 30)
                const end = 230 - (i * 30)
                this.checkAniDice(data, diff, start, end, 32 + i, 23 + i, 23 + i, 2)
              }
              if (diff <= 140 && this.AniDiceRandom[1] === false) {
                this.AniDiceRandom[1] = true
                this.AniDice(6)
                this.AniDice(7)
                this.AniDice(8)
                this.AniDice(9)
                this.AniDice(10)
              }
              if (diff <= 140) {
                this.loading[1] = false
              }
              if (diff <= 139) {
                this.stopDice(6)
              }
              if (diff <= 138) {
                this.stopDice(7)
              }
              if (diff <= 137) {
                this.stopDice(8)
              }
              if (diff <= 136) {
                this.stopDice(9)
              }
              if (diff <= 135) {
                this.stopDice(10)
              }

              if (diff <= 115) {
                this.loading[0] = false
              }
              if (diff <= 115 && this.AniDiceRandom[0] === false) {
                this.AniDiceRandom[0] = true
                this.AniDice(1)
                this.AniDice(2)
                this.AniDice(3)
                this.AniDice(4)
                this.AniDice(5)
              } else if (diff === 1) {
                setTimeout(() => {
                  this.stopDice(1)
                }, 1000)
                setTimeout(() => {
                  this.stopDice(2)
                }, 2000)
                setTimeout(() => {
                  this.stopDice(3)
                }, 3000)
                setTimeout(() => {
                  this.stopDice(4)
                }, 4000)
                setTimeout(() => {
                  this.stopDice(5)
                }, 5000)
              }
            }
            // แถวสาม
          }
        }
      }
    },
    checkAniDice (data, diff, start, end, dic, check, load, digit) {
      if (diff <= start) {
        this.loading[load] = false
        this.first.fivearray = data.fivearray
      }
      if (diff <= start && this.AniDiceRandom[check] === false) {
        this.AniDiceRandom[check] = true
        if (digit === 5) { this.AniDice5(dic) } else if (digit === 4) { this.AniDice4(dic) } else if (digit === 3) { this.AniDice3(dic) } else if (digit === 2) { this.AniDice2(dic) }
      }
      if (diff <= end) {
        this.stopDice(dic)
      }
    },
    renderCountDown (datetime) {
      // Find the distance between now and the count down date
      // const t1 = moment().tz('Asia/Bangkok')
      // const t2 = moment(datetime).tz('Asia/Bangkok')
      // const distance = t2.diff(t1, 'seconds')
      // console.log(distance);
      const date2 = moment(datetime);
      const now = moment();
      const duration = moment.duration(date2.diff(now));
      // Time calculations for days, hours, minutes and seconds
      var hours = ('0' + duration.hours()).slice(-2)
      var minutes = ('0' + duration.minutes()).slice(-2)
      var seconds = ('0' + duration.seconds()).slice(-2)
      // Display the result in an element with id="demo"
      this.countdown = hours + ':' + minutes + ':' + seconds

      // If the count down is finished, write some text
    }
  }
}
</script>
<style scoped>
@media only screen and (min-width: 992px){
            #modal-next-draw-dates .modal-dialog {
                width: 500px;
            }
        }
        
        #modal-next-draw-dates .modal-dialog .modal-content .modal-body {
            padding: 0;
            margin-top: -1px;
        }
        
        #modal-next-draw-dates .modal-footer {
            padding: 5px;
            text-align: center;
            border-top: none;
            color: #000000;
            background: #ffffff;
        }
        
        #modal-next-draw-dates .modal-header {
            background-image: url(https://www.magnum4d.my/Magnum4d/media/images/footer/element-goldbox.jpg);
            padding: 5px 0;
            color: #000000;
        }
    
        #modal-next-draw-dates .modal-header .close {
            color: #000000;
            font-size: 30px;
        }
    
        #modal-next-draw-dates .win-cup {
            width: 50px;
            height: 55px;
            display: inline-block;
            vertical-align: middle;
            background: url(https://www.magnum4d.my/magnum4d/assets/images/icons/icon-results.png) 50px 0;
        }
    
        .next-draw-date-picker .datepicker-inline {
            width: 100%;
            padding: 0;
        }
        .next-draw-date-picker .datepicker table {
            width: 100%;
        }
        .next-draw-date-picker .datepicker-days table {
            border-collapse: collapse;
            border-spacing: 0px;
            background-color: #ffa500;
        }
    
        .next-draw-date-picker .datepicker-days table thead tr:nth-child(2){
            background-color: #ffa500;
        }
    
        .next-draw-date-picker .datepicker-days table thead tr:nth-child(3){
            background-color: #cccccc;
            color: #000000;
        }
    
        .next-draw-date-picker .datepicker-days table tbody tr {
            background-color: #eeeeee;
        }
    
        .next-draw-date-picker .datepicker .datepicker-switch, 
        .next-draw-date-picker .datepicker .prev, 
        .next-draw-date-picker .datepicker .next, 
        .next-draw-date-picker .datepicker tfoot tr th {
            border-radius: 0;
            padding: 5px;
            color: #ffffff;
            font-size: 14px;
        }
    
        .next-draw-date-picker .table-condensed > thead > tr > th {
            border-radius: 0;
        }
    
        .next-draw-date-picker .datepicker .datepicker-switch:hover, 
        .next-draw-date-picker .datepicker .prev:hover, 
        .next-draw-date-picker .datepicker .next:hover, 
        .next-draw-date-picker .datepicker tfoot tr th:hover {
            background-color: #ffbe47;
        }
    
        .next-draw-date-picker .datepicker table tr td.disabled, 
        .next-draw-date-picker .datepicker table tr td.disabled:hover {
            padding: 10px 0;
        }
        .next-draw-date-picker .day.disabled.draw-date-active{
            opacity: 1;
        }
    
        .next-draw-date-picker .datepicker table tr td span {
    
        }
    
        .next-draw-date-picker .day.disabled.draw-date-active span {
            line-height: inherit;
            float: none;
            width: initial;
            height: initial;
            cursor: unset;
        }
        .next-draw-date-picker .day.disabled.draw-date-active span.draw-date {
            background-color: #ffc20f;
            color: #000000;
            border-radius: 30px !important;
            padding: 8px;
            display: initial;
        }
        
        .next-draw-date-picker .day.disabled.draw-date-active span.draw-Id {
            display: block;
            color: #000000;
            padding-top: 8px;
        }
    
        .next-draw-date-picker .day.disabled.draw-date-active:hover {
            opacity: 1;
            background-color: unset !important;
        }
        .next-draw-date-picker .datepicker table tr td.active.draw-date-active,
        .next-draw-date-picker .datepicker table tr td.active {
            background-image: none;
            background-color: #ffc20f !important;
            opacity: 1;
        }
    
        .next-draw-date-picker .datepicker table tr td {
            border-radius: 0 !important;
        }
    
        
        .datepicker.datepicker-orient-top {
            z-index: 1060;
        } 
        .fill-spacing {
                height: 50px;
                display: none;
            }
            header.is-fixed ~ .fill-spacing {
                display: block;
            }

            .container.affix {
  position: static; }
  @media (max-width: 767px) {
    .container.affix {
      position: fixed;
      z-index: 100;
      top: 50px;
      background: #ffffff;
      width: 100%;
      left: 0; }
    .container.affix.with-topnotice {
      top: 100px; } }

  @media (max-width: 767px) {
    .container.affix .filter-title {
      padding: 0 10px;
      display: none; } }

  @media (max-width: 767px) {
    .container.affix .page-tabs-wrapper {
      display: none; } }

  @media (max-width: 767px) {
    .container.affix .filter-wrapper {
      padding: 0 10px; } }

  @media (max-width: 767px) {
    .affix ~ .page-wrapper {
      position: relative;
      margin-top: 229px; } }
      
    #filter-row .select-control:after {
        content: "";
    }
    
    .heading-black-wrapper .btn-print, .heading-black-wrapper-xs .btn-print {
        right: 70px;
    }

    .heading-black-wrapper .btn-draw-show-hide, .heading-black-wrapper-xs .btn-draw-show-hide {
        position: absolute;
        color: #ffffff;
        font-size: 32px;
        right: 0px;
        top: 8px;
    }

    .heading-black-wrapper .btn-draw-show-hide:hover {
        text-decoration: none;
    }

    .heading-black-wrapper .btn-draw-show-hide i.fa-angle-up {
        display: block;
    }

    .heading-black-wrapper .btn-draw-show-hide i.fa-angle-down {
        display: none;
    }

    .heading-black-wrapper .btn-draw-show-hide.collapsed i.fa-angle-up {
        display: none;
    }

    .heading-black-wrapper .btn-draw-show-hide.collapsed i.fa-angle-down {
        display: block;
    }

    @media only screen and (min-width: 992px){
        .heading-black-wrapper .btn-print, .heading-black-wrapper-xs .btn-print {
            right: 70px;
        }
        .heading-black-wrapper .btn-draw-show-hide, .heading-black-wrapper-xs .btn-draw-show-hide {
            right: 0px;
        }
    }
    /* .responsive-image {
        width: 100%;
        height: auto;
        max-width: 100%;
    } */
</style>
